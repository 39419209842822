table {
  border-collapse: collapse;
  border: 1px solid #b0b0b0;
}
* {
  font-family: Gisha, Arial;
  font-size: 13px;
  color: #333;
  line-height: 18px;
}
td {
  text-align: Left !important;
}

.borderB td {
  border-color: #b0b0b0;
}
.borderB {
  border-bottom: 0 none;
  border-left: 0 none;
}

.inputSearchDesktop {
  width: 300px !important;
}
/* 
.ui.input {
  width: 100px;
} */
