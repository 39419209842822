.InvoiceTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.InvoiceTable td,
.InvoiceTable th {
  border: 1px solid #ddd;
  padding: 3px;
}

.InvoiceTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.InvoiceTable tr:hover {
  background-color: #ddd;
}

.InvoiceTable th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background-color: #34495e;
  color: white;
}

.box-Scroll {
  height: 70vh;
  width: 100%;
  overflow-y: scroll;
  padding: 20px;
  margin-bottom: 30px;
}
