/* html{
    overflow: hidden;
} */

body{
    overflow-x: hidden;
    overflow-y: hidden !important;
}

.MuiDrawer-root .MuiTypography-root{
    color: black !important;
    background:transparent !important;
}

.selhov{
    background-color: white !important;
    color: black !important;
}

.selhov:hover{
    background-color: #333 !important;
    color: white !important;
}

.lihov{
    color: white;
    background-color: grey;
}

.lihov:hover{
    color: white !important;
    background-color: #333 !important;
}

.divIdToPrintDesktop{
    flex: 1;
    height: 100vh;
    display: flex;
    background-color: #fff;
    flex-direction: row;
    background-repeat: no-repeat;
    background-size: 180vh;
}

@media only screen and (min-width:992px){
    .SearchBoxItemDesktop{
        position: absolute !important;
        margin-top: 10% !important;
        z-index: 1000 !important;
    }
}

@media only screen and (min-width:300px) and (max-width:450px){
    .MuiContainer-maxWidthLg{
        display: block !important;
    }
    .iimglogg{
        width: 25%;
    }
    body{
        overflow-x: hidden;
        overflow-y: scroll !important;
        /* overflow-y: hidden !important; */
    }
    .divIdToPrintMobile{
        flex: 1;
        height: 120vh;
        display: flex;
        background-color: #333;
        flex-direction: row;
        /* background-image: url("./q_img.png"); */
    }
    .divTopTenMobile{
        margin-left: 20%;
        margin-top: 50%;
        border-radius: 3px;
        /* position: absolute; */
        /* left: 150px; */
        display: flex;
        height: 42vh;
        background-color: #bdbebf;
        width: 90vw;
        flex-direction: column;
    }
    .MuiDialog-paperScrollPaper{
        width: 100% !important;
    }
    .divOpenCredMobile{
        display: flex;
        flex-direction: row;
        /* padding: 50px; */
    }
    .txtEmailMobile{
        margin: 5% 5% 0% 5% !important;
        width: 90% !important;
    }
    .btnEmailMobile{
        margin: 5% 5% 5% 5% !important;
        width: 90% !important;
    }
    .modalQuizMobile{
        height: 90vh;
        flex-direction: column;
        background-color: #fafafa;
        width: 100vw;
        overflow: scroll;
    }
    .submitQuestionMobile{
        width: 50% !important;
        margin-top: 15% !important;
    }
    .xHRlineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 200% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
    .xHRClineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 90% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
    .divSearchBoxMobile{
        display: flex;
        flex-direction: row;
        margin: 5% 2.5% 10% 2.5% !important;
        padding-left: 10%;
        padding-top: 50%;
        /* background-color:#fff; */
        /* justify-content: center; */
        /* flex-direction:column; */
        /* align-items: center; */
    }
}

@media only screen and (min-width:450px) and (max-width:800px){
    .MuiContainer-maxWidthLg{
        display: block !important;
    }
    .iimglogg{
        width: 20%;
    }
    body{
        overflow-x: hidden;
        overflow-y: scroll !important;
        /* overflow-y: hidden !important; */
    }
    .divIdToPrintMobile{
        flex: 1;
        height: 140vh;
        display: flex;
        background-color: #333;
        flex-direction: row;
        /* background-image: url("./q_img.png"); */
    }
    .divTopTenMobile{
        margin-left: 20%;
        margin-top: 40%;
        border-radius: 3px;
        /* position: absolute; */
        /* left: 150px; */
        display: flex;
        height: 42vh;
        background-color: #bdbebf;
        width: 90vw;
        flex-direction: column;
    }
    .MuiDialog-paperScrollPaper{
        width: 100% !important;
    }
    .divOpenCredMobile{
        display: flex;
        flex-direction: row;
        /* padding: 50px; */
    }
    .txtEmailMobile{
        margin: 5% 5% 0% 5% !important;
        width: 90% !important;
    }
    .btnEmailMobile{
        margin: 5% 5% 5% 5% !important;
        width: 90% !important;
    }
    .modalQuizMobile{
        height: 90vh;
        flex-direction: column;
        background-color: #fafafa;
        width: 100vw;
        overflow: scroll;
    }
    .submitQuestionMobile{
        width: 50% !important;
        margin-top: 15% !important;
    }
    .xHRlineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 200% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
    .xHRClineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 90% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
}

@media only screen and (min-width:768px) and (max-width:992px){
    .MuiContainer-maxWidthLg{
        display: block !important;
    }
    .iimglogg{
        width: 15%;
    }
    body{
        overflow-x: hidden;
        overflow-y: scroll !important;
        /* overflow-y: hidden !important; */
    }
    .divIdToPrintMobile{
        flex: 1;
        height: 140vh;
        display: flex;
        background-color: #333;
        flex-direction: row;
        /* background-image: url("./q_img.png"); */
    }
    .divTopTenMobile{
        margin-left: 20%;
        margin-top: 40%;
        border-radius: 3px;
        /* position: absolute; */
        /* left: 150px; */
        display: flex;
        height: 42vh;
        background-color: #bdbebf;
        width: 90vw;
        flex-direction: column;
    }
    .MuiDialog-paperScrollPaper{
        width: 100% !important;
    }
    .divOpenCredMobile{
        display: flex;
        flex-direction: row;
        /* padding: 50px; */
    }
    .txtEmailMobile{
        margin: 5% 5% 0% 5% !important;
        width: 90% !important;
    }
    .btnEmailMobile{
        margin: 5% 5% 5% 5% !important;
        width: 90% !important;
    }
    .modalQuizMobile{
        height: 90vh;
        flex-direction: column;
        background-color: #fafafa;
        width: 100vw;
        overflow: scroll;
    }
    .submitQuestionMobile{
        width: 50% !important;
        margin-top: 15% !important;
    }
    .xHRlineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 200% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
    .xHRClineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 90% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
}

::-webkit-scrollbar {
    width: 2%;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #f50057; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
    /* cursor: pointer !important; */
  }

.logoDivDesktop{
    /* width: '20vw'; */
    flex: 4;
    background-color: #ededed;
    display: flex;
    justify-content: center;
    padding: 10%;
    /* alignItems: 'center'; */
}

.logoDivMobile{
    /* width: '20vw'; */
    flex: 4;
    /* backgroundColor: '#eeeeee'; */
    display: flex;
    justify-content: center;
    padding: 1%;
    /* alignItems: 'center'; */
}

.logoDivImgDesktop{
    margin-left: 10%;
    margin-top: 5%;
    width:95%;
    height:8%;
}

.logoDivImgMobile{
    margin-top: 25%;
    width:90px !important;
    height:30px;
}

.anchorlogoDivImgMobile{
    /* margin-left: 23%; */
    float: right;
}

.rightAreaDesktop{
    /* width: '90vw'; */
    flex: 10;
    display: flex;
    /* justify-content: 'center', */
    flex-direction: column;
    align-items: center;
}

.rightAreaMobile{
    /* width: '90vw'; */
    flex: 10;
    display: flex;
    /* justify-content: 'center', */
    flex-direction: column;
    align-items: center;
}

.rightHeaderDesktop{
    height: 10vh;
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
}

.rightfooterDesktop{
    height: 7vh;
    width: 100vw;
    background-color: #333;
    display: flex;
    
    flex-direction: row;
    align-items: flex-end;
    position: fixed;
    bottom: -0px;
}
.rightfooterDesktop_contact{
    height: 7vh;
    width: 100vw;
    background-color: #333;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-end;
    position: fixed;
    bottom: -0px;
}

.rightHeaderMobile{
    /* display: none !important; */
    margin-left: -4%;
    margin-top: 20%;
    position: absolute;
    background-color: #333;
    /* background:rgba(0,0,0,0); */
    opacity: 1;
    z-index: 1000;
    height: 100vh;
    /* border:1px solid white; */
    width: 60vw;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}

.tblHeadMobile{
    padding: 5%;
    width: 100%;
    position: absolute;
    background-color: #333;
    color: white !important;
}

.tblHeadMobile h5{
    width: 100%;
    padding: 4%;
    /* background-color:#3498db ; */
    /* border:1px solid rgba(0,0,0,0.6) !important; */
}

.btactive:hover{
    /* background-color:#2ecc71 ; */
    background-color:#2ecc71 !important;
}

.btactive{
    background-color:#3498db !important;
}

.fa-bars{
    width: 100% !important;
}

.fa-times{
    display: none;
    padding-left: 18% !important;
    /* margin-right: 10% !important; */
}

.homeButtonDesktop{
    font-weight: bold;
    cursor: pointer;
    margin-left: 4%; 
    font-size: 100%; 
    display: flex; 
    font-family: Arial, Helvetica, sans-serif; 
    color: #fff;
}

.homeButtonMobile{
    font-weight: bold;
    cursor: pointer;
    margin-left: 4%; 
    font-size: 100%; 
    display: flex; 
    font-family: Arial, Helvetica, sans-serif; 
    color: #fff;
}

.homeButtonDesktop:hover{
    color: #74b9ff;
}

.homeButtonMobile:hover{
    color: #74b9ff;
}

.dashButtonDesktop{
    margin-right: 20px;
    font-weight: bold;
    cursor: pointer;
    font-size: 100%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
}

.dashButtonMobile{
    font-weight: bold;
    cursor: pointer;
    margin-left: 3%;
    font-size: 100%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
}

.dashButtonDesktop:hover{
    color: #74b9ff;
}

.dashButtonMobile:hover{
    color: #74b9ff;
}

.divQubitImgDesktop{
    
    height: 20vh;
    background-color: #fff;
    justify-content: center;
    /* flex-direction:'column'; */
    padding-left: 1%;
    align-items: center;
}

.divQubitImgMobile{
    height: 20vh;
    background-color: #333;
    justify-content: center;
    /* flex-direction:'column'; */
    padding-left: 20%;
    padding-top: 10%;
    align-items: center;
}

.QubitImgDesktop{
    width:160px;
    height:55px;
}

.QubitImgMobile{
    margin-left: 21%;
    width:130px;
    height:45px;
}

.QubitHeadlineDesktop{
    color: #fff;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 'bold';
    font-size: 110%;
    margin-top: 3%;
}

.QubitHeadlineMobile{
    color: #fff;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 'bold';
    font-size: 150%;
    margin-top: 10%;
}

.QubitParaDesktop{
    text-align: center;
    opacity: 0.7;
    font-size: 90%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
    width: 83%;
    font-weight: 500;
    font-size: larger;
}

.QubitParaMobile{
    position: absolute !important;
    margin-top: 40%;
    padding: 3%;
    text-align: center;
    font-size: 110%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color:white;
}

.divSearchBoxDesktop{
    display: flex;
    flex-direction: row;
    margin-left:-22.5% ;
    padding-left: 1%;
    /* background-color:#fff; */
    /* justify-content: center; */
    /* flex-direction:column; */
    /* align-items: center; */
}

.divSearchBoxMobile{
    display: flex;
    flex-direction: row;
    margin: 2.5% 2.5% 10% 2.5%;
    padding-left: 10%;
    padding-top: 50%;
    /* background-color:#fff; */
    /* justify-content: center; */
    /* flex-direction:column; */
    /* align-items: center; */
}

.SearchBoxDesktop{
    font-weight: bold;
    position: absolute;
    /* margin:5px; */
    margin-left: -30%;
    margin-top:0.3%;
    justify-content:flex-end;
    font-size: 90%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 29%;
}

.SearchBoxMobile{
    font-weight: bold;
    position: absolute;
    /* margin:5px; */
    margin-left: -35%;
    margin-top:3%;
    justify-content:flex-end;
    font-size: 100%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 75%;
}

.divSearchBarDesktop{
    display: flex;
    position: relative;
    left: 100px;
    z-index: 1000;
    flex-direction: column;
}

.divSearchBarMobile{
    margin-left: 20%;
    display: flex;
    position: absolute;
    z-index: 999;
    flex-direction: column;
}

.SearchBarDesktop{
    padding-left: 1.5%;
    background-color: #eeeeee;
    height: 5.2vh;
    border-radius: 3px;
    font-size: 18px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 500px;
    border: 1px solid #cccccc;

}
.ccc{
    border: 1px solid #cccccc;
    height: 30px;
    padding: 10px;
  

}
.SearchBarMobile{
    padding-left: 5px;
    background-color: #8e8f91;
    height: 30px;
    border-radius: 3px;
    font-size: 14px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 45%;
    margin-top: 7%;
    margin-left: -19%;
}

.SearchBoxItemDesktop{
    background-color: #eeeeee;   
    width: 500px;
   
}

.SearchBoxItemMobile{
    background-color: #fff;
}

.inputSearchDesktop{
    width: 500px !important;
}

.inputSearchMobile{
    width: 600px !important;
}

.inputSearchDesktop input{
    font-size: 92%;
    padding-left: 0%;
    padding-right: 2%;
}

.inputSearchMobile input{
    font-size: 95%;
    padding-left: 0%;
    padding-right: 2%;
}

.divSearchBarMobile span+span{
    width: 37.8% !important;
    margin-left: 1% !important;
}

.selcourse input{
    text-align: center !important;
}

.numques input{
    text-align: center !important;
}

.listSearchDesktop{
    cursor: pointer;
    border: 1px solid #cccccc;
    margin: 0px;
    padding: 4px;
    font-size: 14px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
}

.listSearchMobile{
    cursor: pointer;
    /* border: 1px solid #eeeeee; */
    width: 119%;
    margin-left: -18.9%;
    padding: 4px;
    font-size: 12px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
}

.listSearchDesktop:hover{
    color:#eeeeee;
    background-color: #333;
}

.listSearchMobile{
    color:#333;
    background-color: white;
}

.listSearchMobile:hover{
    color:#eeeeee;
    background-color: #333;
}

.divSelectNumberDesktop{
    display: flex;
    margin: 10px;
    margin-left: 100px;
}

.divSelectNumberMobile{
    z-index: -1;
    position: absolute;
    display: flex;
    margin-top: 12%;
    margin-left: 16%;
}

.SelectNumberDesktop{
    margin-top: 3%;
    font-size: 16px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
    opacity: 0.7;
}

.SelectNumberDesktop_tq{
    margin-top: 12px;
    position: absolute;
    left: -35px;
    font-size: 16px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
    opacity: 0.7;
}

.SelectNumberMobile{
    margin-top: 8%;
    margin-left: -40%;
    font-size: 14px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
}

.inputNumberDesktop{
    margin-top: 8px;
    margin-right: 5px;
    padding-left: 5px;
    justify-content: flex-start;
    background-color: #eeeeee;
    height: 25px;
    border-right: 40px;
    font-size: 16px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 40px;
}

.divSelectNumberMobile .inputNumberMobile{
    margin-top: 16%;
    margin-right: 5px;
    padding-left: 5px;
    margin-left: -30% !important;
    width: 10% !important;
    justify-content: flex-start;
    background-color: #fff;
    height: 25px;
    border-right: 3px;
    font-size: 10px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 30px;
}

.inputNumDesktop{
    width: 600;
}

.inputNumMobile{
    width: 600;
}

.inputNumDesktop input{
    border-radius: 5px;
    font-size: 16px;
    padding: 1%;
    font-weight: bold;
}

.divSelectNumberMobile .inputNumMobile input{
    font-size: 100%;
    padding: 1%;
    font-weight: bold;
}

.divGoButtonDesktop:hover{
    cursor: pointer;
}

.divSelectNumberMobile .divGoButtonMobile{
    margin-top: 14.5%;
}

.divSelectNumberMobile .divGoButtonMobile:hover{
    cursor: pointer;
}

.divTopTenDesktop{
    margin-left: 100px;
    margin-top: -1.5%;
    border-radius: 3px;
    position: relative;
    left: 150px;
    display: flex;
    height: 44vh;
    background-color: #bdbebf;
    width: 35vw;
    flex-direction: column;
}

.TopTenDesktop{
    margin: 3% 0% 2% 3%;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
}

.TopTenMobile{
    margin: 3% 0% 2% 3%;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
}

.listTopTenDesktop{
    /* width: 100vh; */
    margin: 0px;
    padding: 1% 0% 0% 2%;
    font-size: 12px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
}

.listTopTenMobile{
    padding: 1.5% 0% 0% 2%;
    font-size: 12px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
}

.listTopTenDesktop:hover{
    color:#34495e;    
    cursor: pointer;
    font-size: 12.5px;
}

.listTopTenMobile:hover{
    color:#34495e;
    cursor: pointer;
    font-size: 12.5px;
}

.divLastDesktop{
    /* width: 60; */
    flex: 2;
    display: flex;
    /* justifyContent: 'center'; */
    background-color: #333;
}

.divLastMobile{
    /* width: 60; */
    flex: 2;
    display: flex;
    /* justifyContent: 'center'; */
    background-color: #333;
}

.navbarMobile{
    height:8.5vh;
    /* padding-bottom: 20%; */
    background-color:#f3f3f3;
}

.divmainMobile{
    background-color:#333 !important;
}