::-webkit-input-placeholder {
    color: rgba(0,0,0,0.7) !important;
    
}

.ui.modal>.actions{
    padding: 2rem 1rem !important;
}

.ui.modal .scrolling.content{
    padding: 0px !important;
}

::-webkit-scrollbar-track{
    border-radius: 0px !important;
    background-color: rgba(255,255,255,0.6) !important;
}

.ui.modal>.close{
    padding: .525rem 0 0 0 !important;
    background: rgba(255,255,255,1) !important;
    border-radius: 100% !important;
    right: -0.4% !important;
}


th{
    text-align: center !important;
    width: 33.3% !important;
}

td{
    text-align: center !important;
}


.conversion thead th { 
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1.5% 0% 1.5% 0% !important;
}