
::-webkit-input-placeholder {
  color: rgba(0,0,0,0.7) !important;
} 
  .ReactTable .rt-thead.-header{
   background:#eb4d4b !important; color:white;  
    /* border:1px solid  black; */

  }
  
 .ReactTable .rt-thead.-headerGroups {
    background:#eb4d4b !important; color:white;
    border:1px solid black;
  } 
.ReactTable .rt-thead .rt-th{
    border-right: 1px solid black;
}
.ReactTable{
    border: 1px solid black;
}




 /* th{
  border: 3pt solid rgb(132, 133, 138) !important;
} */
.radioForm label{
  color: white !important;
}

.sticky-table-table{
  width: 100% !important;
}

.checkbox label{
  color: white !important;
}

i{
  color: black !important;
}

.iFUdnr{
  width:100% !important;
}

table{
  border-spacing: 0px !important;
  border-collapse: collapse !important;
}

.scrolling.dimmable.dimmed>.dimmer{
  overflow: hidden !important;
}

.ui.modal .scrolling.content{
  max-height: calc(80vh) !important;
}

.modals.dimmer .ui.scrolling.modal{
  max-height: calc(95vh) !important;
  margin-top: 1% !important;
}

::-webkit-input-placeholder {
  color: rgba(0,0,0,0.7) !important;
} 


#conversion thead th { 
  position: sticky !important;
  background: rgb(37, 40, 57) !important;
  color: white !important;
  
}

