.grid-container {
    display: grid;
    grid-template-columns: 300px 300px 100px 100px;
    grid-gap: 10px;
    
    padding: 10px;
  }
  
  .grid-container > div {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px 0;
    font-size: 14px;    
  }
  label
  {
      font-weight: bold;
      font-size: 15px;
  }
  
  .headerBackGround
  {
    background: radial-gradient(#007bff, #8ffbf6);
  }
  .panelBorder
  {
    border: 1px solid #eceaea;
    border-radius: 10px
  }