.activeDateSpan {
    border-bottom: 3px groove #000306 !important;
}

.custumDataTable {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.custumDataTable .custumRow {
    border: 1px solid #dee2e6 !important;
}

table#as-react-datatable td {
    padding: .75rem !important;
    vertical-align: top !important;
    border: 1px solid #dee2e6 !important;
}

.custumAutoCompleteList {
    position: absolute;
    border: 1px solid #d0cccc;
    background-color: white;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
}

.sweetAlertWidth {
    width: 98% !important;
}

.sweetAlertWidth30 {
    width: 30% !important;
}

.sweetAlertWidth50 {
    width: 50% !important;
}

path {
    color: red !important;
}