.accordion-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.accordion-header {
    background-color: #f1f1f1;
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.accordion-header::after {
    content: '\25B6';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
}

.accordion-header.active::after {
    transform: translateY(-50%) rotate(90deg);
}

.accordion-content {
    padding: 10px;
    display: none;
    background-color: #fff;
    transition: max-height 0.3s ease;
    overflow: hidden;
}

.accordion-content p {
    margin: 0;
}