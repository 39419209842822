td:active {
    background: none !important;
}

.radioForm label {
    color: white !important;
}

.sticky-table-table {
    widows: 100% !important;
}

.SPradioDiv .checkbox:first-child label {
    color: rgb(73, 82, 133) !important;
}

.SPradioDiv .checkbox label {
    color: rgb(0, 181, 173) !important;
}


i {
    color: black !important;
}

.iFUdnr {
    width: 100% !important;
}

table {
    border-spacing: 0px !important;
    border-collapse: collapse !important;
}

.scrolling.dimmable.dimmed>.dimmer {
    overflow: hidden !important;
}

.ui.modal .scrolling.content {
    max-height: calc(80vh) !important;
}

.modals.dimmer .ui.scrolling.modal {
    max-height: calc(95vh) !important;
    margin-top: 1% !important;
}

::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.7) !important;
}

#cconversion thead tr:nth-child(2) th {
    font-size: 85% !important;
    z-index: 999 !important;
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: 5.5% !important;
    padding: 1.2% 0% 1.2% 0% !important;
    width: auto !important;
}

#cconversion thead tr:nth-child(1) th {
    font-size: 85% !important;
    z-index: 999 !important;
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    width: auto !important;
}

.cconversion thead th {
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1% 0% 1% 0% !important;
    width: auto !important;
}

#cconversion {
    width: 100% !important;
    border: 3pt solid rgb(73, 82, 133) !important;
}

#cconversion td {
    padding: 0.5% 0.1% 0.5% 0.1% !important;
    border: 0.5px solid rgb(73, 82, 133) !important;
}

#cconversion td:first-child {
    font-weight: bold !important;
}

#cconversion tr {
    border-bottom: 3pt solid rgb(73, 82, 133) !important;
}

#cconversion td {
    vertical-align: middle !important;
}

/* #cconversion td:first-child:hover, .cvtable td:nth-child(2):hover, .cvtable td:nth-child(3):hover, .cvtable td:nth-child(4):hover{
    color:black !important;
    cursor: auto !important;
} */

.cvtable tr td:first-child:hover {
    zoom: 1 !important;
    background: rgb(255, 255, 255) !important;
}

#cconversion tr td:first-child:active {
    background: rgb(255, 255, 255) !important;
}

#cconversion thead tr th:first-child {
    width: 12% !important;
}

/* #cconversion td:nth-child(1):hover
,#cconversion td:nth-child(3):hover,#cconversion td:nth-child(4):hover,#cconversion td:nth-child(5):hover
,#cconversion td:nth-child(7):hover,#cconversion td:nth-child(8):hover,#cconversion td:nth-child(9):hover
,#cconversion td:nth-child(11):hover,#cconversion td:nth-child(12):hover,#cconversion td:nth-child(13):hover
,#cconversion td:nth-child(15):hover,#cconversion td:nth-child(16):hover,#cconversion td:nth-child(17):hover
{
    cursor: auto !important;
    background: rgb(255,255,255) !important;
    color: black !important;
} */

#cconversion td:nth-child(1):active,
#cconversion td:nth-child(3):active,
#cconversion td:nth-child(4):active,
#cconversion td:nth-child(5):active,
#cconversion td:nth-child(7):active,
#cconversion td:nth-child(8):active,
#cconversion td:nth-child(9):active,
#cconversion td:nth-child(11):active,
#cconversion td:nth-child(12):active,
#cconversion td:nth-child(13):active,
#cconversion td:nth-child(15):active,
#cconversion td:nth-child(16):active,
#cconversion td:nth-child(17):active {
    zoom: 1 !important;
}

.cvtable td:hover {
    cursor: pointer !important;
    background: rgb(0, 181, 173) !important;
    color: white !important;
}

#cconversion td:active {
    zoom: 0.95 !important;
    background: rgb(0, 181, 173) !important;
}

#cconversion td {
    font-size: 90% !important;
}

.cconversion td {
    text-align: center !important;
}

.masterDiv {
    overflow-x: scroll !important;
    overflow-y: scroll !important;
    max-height: 100vh !important;
}

/* width */
.masterDiv::-webkit-scrollbar {
    height: 12px !important;
}


.firstR {
    color: white !important;
}

.cvtable td:first-child {
    background: rgb(73, 82, 133, 1) !important;
}

.cvtable td:first-child:hover {
    background: rgb(73, 82, 133, 0.7) !important;
}

.cvtable td:first-child:hover .firstR {
    color: black !important;
}

.cvtable td:first-child label {
    font-weight: bold !important;
}

.cconversion tbody tr:last-child td {
    font-weight: bold !important;
}

.cconversion thead th:first-child {
    width: 10% !important;
}

.cconversion thead th:nth-child(2) {
    width: 15% !important;
}

.cconversion thead th:nth-child(3) {
    width: 25% !important;
}

.cconversion thead th:nth-child(4) {
    width: 20% !important;
}

.cconversion thead th:nth-child(5) {
    width: 15% !important;
}

.cconversion thead th:nth-child(6) {
    width: 15% !important;
}

.cvtable tr:nth-child(1) td:first-child,
.cvtable tr:nth-child(1),
.cvtable tr:nth-child(1):hover td {
    background: rgb(37, 40, 57) !important;
    color: white !important;
}

.cvtable tr:nth-child(1) td,
.cvtable tr:nth-child(1) label {
    color: white !important;
}

.cvtable tr:nth-child(1) span,
.cvtable tr:nth-child(1):hover span {
    color: coral !important;
}

.cvtable tr:nth-child(1):hover td,
.cvtable tr:nth-child(1):hover label {
    color: white !important;
}

.cvtable tr:nth-child(1) td:first-child label {
    color: rgb(73, 82, 133, 1) !important;
}

.cvtable tr:nth-child(1):hover td:first-child label {
    color: rgb(37, 40, 57) !important;
}

.cvtable tr:nth-child(1) td .laButton,
.cvtable tr:nth-child(1) td:first-child label {
    display: none !important;
}

.cvtable tr:first-child td {
    z-index: 1000 !important;
    position: sticky !important;
    background: rgb(37, 40, 57) !important;
    color: white !important;
    top: 7% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

.description .ui.fixed.table td,
.ui.fixed.table th {
    white-space: pre-wrap !important;
}

.mainDivCV .clndr-calendars-wrapper,
.mainDivCV .clndr-control,
.mainDivCV .clndr-days,
.mainDivCV .clndr-button-today {
    background: #fff !important;
}

.mainDivCV .clndr-calendars-wrapper {
    width: 102% !important;
    margin: -1% 0% -1% -1% !important;
}

.mainDivCV .clndr-control {
    padding: 3% 2% 0% 2% !important;
}

.mainDivCV .clndr-button-today {
    width: 102% !important;
    margin: -1% 0% -1% -1% !important;
}