table.dataTable thead {
  cursor: pointer;
  background-color: #313f45;
  color: #fff;
}

th {
  text-align: center !important;
  width: auto !important;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone {
  text-align: Right !important;
}

.ui.table:last-child {
  margin-bottom: 0;
  margin-top: 0;
}

#txtareaClaim::placeholder {
  font-size: 30px;
  text-align: center;
  padding-top: 16%;
}

.sweetAlertWidth50 {
  width: 50% !important;
}


.MuiOutlinedInput-input {
  padding: 10.5px 14px !important;
}

/* 
.ui.attached.button {
  position: relative;
  display: inline-block;
  margin: 0;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) !important;
}

.ui[class*="right floated"].header {
  float: right;
  margin-top: -4%;
  margin-left: 0.5em;
} */

.modals.dimmer .ui.scrolling.modal {
  max-height: calc(95vh) !important;
  margin-top: 1% !important;
  margin-left: 30%;
}

.ui.dropdown .menu>.item {
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  height: auto;
  text-align: left;
  border-top: none;
  line-height: 1em;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.78571429rem 1.14285714rem !important;
  font-size: 12px;
  text-transform: none;
  font-weight: 400;
  box-shadow: none;
  -webkit-touch-callout: none;
}

.ui.search {
  font-size: 12px;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
  margin-top: 2vh;
  float: left;
}

.ImageUploadCard-root-41 {
  width: 48vw;
  display: inline-block;
  align-items: flex-end;
  justify-content: center;
  background-color: #fff;
  margin-top: 1vh;
}

.ui.modal>.close {
  padding: 3.525rem 0 0 0 !important;
  background: rgba(255, 255, 255, 1) !important;
  border-radius: 100% !important;
  right: -0.4% !important;
  font-size: 3.25em;
  margin-right: 1em;
}

.modals.dimmer .ui.scrolling.modal {
  max-height: calc(95vh) !important;
  margin-top: 1% !important;
  margin-left: 30%;
  width: 80vw;
  margin-left: 10vw;
}

.ui.segment {
  position: relative;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 1rem 0;
  padding: 0em 0em;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

img {
  vertical-align: middle !important;
  border-style: none !important;
  width: 100px;
  height: 100px;
}

/* .zoom {
  padding: 50px;
  background-color: green;
  transition: transform 0.2s;
  width: 25px;
  height: 25px;
  margin: 0 auto;
} */

img#updateImgrcbLogo:hover {
  -ms-transform: scale(5) !important;
  -webkit-transform: scale(5) !important;
  transform: scale(5) !important;
  z-index: 99999;
  position: relative;
}

/* .ui.table thead th {
  cursor: auto;
  background: #313f45;
  text-align: inherit;
  color: #fff;
  padding: 0.92857143em 0.78571429em;
  vertical-align: inherit;
  font-style: none;
  font-weight: 700;
  text-transform: none;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  border-left: none;
  white-space: nowrap;
} */

.ui.table.dataTable thead th {
  cursor: pointer;
  white-space: nowrap;
  border-left: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.table.dataTable thead th:first-child {
  border-left: none;
}

.ui.table.dataTable thead .sorting,
.ui.table.dataTable thead .sorting_asc,
.ui.table.dataTable thead .sorting_desc,
.ui.table.dataTable thead .sorting_asc_disabled,
.ui.table.dataTable thead .sorting_desc_disabled,
.ui.table.dataTable thead .sorting:hover,
.ui.table.dataTable thead .sorting_asc:hover,
.ui.table.dataTable thead .sorting_desc:hover,
.ui.table.dataTable thead .sorting_asc_disabled:hover,
.ui.table.dataTable thead .sorting_desc_disabled:hover {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ui.table.dataTable thead th:after {
  display: none;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "";
  height: 1em;
  width: auto;
  opacity: 0.8;
  margin: 0em 0em 0em 0.5em;
  font-family: "Icons";
}

.ui.table.dataTable thead th.sorting_asc:after {
  /*content: '\f0d8';*/
  content: "\f160";
}

.ui.table.dataTable thead th.sorting_desc:after {
  /*content: '\f0d7';*/
  content: "\f161";
}

.ui.table.dataTable thead th.sorting:after {
  content: "\f0dc";
  opacity: 0.2;
}

/* Hover */

.ui.table.dataTable th.disabled:hover {
  cursor: auto;
  color: rgba(40, 40, 40, 0.3);
}

.ui.table.dataTable thead th:hover {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.8);
}

/* Sorted */

.ui.table.dataTable thead .sorting_asc,
.ui.table.dataTable thead .sorting_desc,
.ui.table.dataTable thead .sorting_asc_disabled,
.ui.table.dataTable thead .sorting_desc_disabled {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
}

.ui.table.dataTable thead .sorting:after,
.ui.table.dataTable thead .sorting_asc:after,
.ui.table.dataTable thead .sorting_desc:after,
.ui.table.dataTable thead .sorting_asc_disabled:after,
.ui.table.dataTable thead .sorting_desc_disabled:after {
  display: inline-block;
}

/* Sorted Hover */

.ui.table.dataTable thead .sorting_asc:hover,
.ui.table.dataTable thead .sorting_desc:hover,
.ui.table.dataTable thead .sorting_asc_disabled:hover,
.ui.table.dataTable thead .sorting_desc_disabled:hover {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
}

.dataTables_length select {
  background: #fff none repeat scroll 0 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.285714rem;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  display: inline-block;
  line-height: 1.2142em;
  min-height: 0.714286em;

  outline: 0 none;
  padding: 0.3em;
  transform: rotateZ(0deg);
  transition: box-shadow 0.1s ease 0s, width 0.1s ease 0s;
  white-space: normal;
  word-wrap: break-word;
}

.dataTables_wrapper .dataTables_filter {
  text-align: right;

  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  position: relative;
}

.dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em;
}

.dataTables_wrapper .dataTables_info {
  clear: both;
  padding-top: 0.755em;
}

.dataTables_filter input {
  background: #fff none repeat scroll 0 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.285714rem;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
  flex: 1 0 auto;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  height: 1em;
  margin: 0;
  max-width: 100%;
  outline: 0 none;
  padding: 0.4em;
  text-align: left;
  transition: background-color 0.1s ease 0s, box-shadow 0.1s ease 0s,
    border-color 0.1s ease 0s;
}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.7) !important;
}