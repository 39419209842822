body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ui.secondary.vertical.pointing.menu .active.item{
  background: #00b5ad;
  border:none !important;
}

i.circular.icon{
  z-index: 1000 !important;
  font-size:1.3rem !important;
  margin: 1rem 0rem 0rem 0rem !important;
}
.link{
  font-weight: 800;
  color: #eeeeee;
  font-size:14;
}
.link_span{
  margin: 5px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
table{
border-spacing: 10px;
    border-collapse: separate}

    .btn-primary {
      color: #fff;
      background-color: #337ab7;
      border-color: #2e6da4;
      padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    }
    .btn-primary:focus,
    .btn-primary.focus {
      color: #fff;
      background-color: #286090;
      border-color: #122b40;
    }
    .btn-primary:hover {
      color: #fff;
      background-color: #286090;
      border-color: #204d74;
    }
    .btn-primary:active,
    .btn-primary.active,
    .open > .dropdown-toggle.btn-primary {
      color: #fff;
      background-color: #286090;
      border-color: #204d74;
    }

    .btn-success {
      color: #fff;
      background-color: #5cb85c;
      border-color: #4cae4c;
      padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    }
    .btn-success:focus,
    .btn-success.focus {
      color: #fff;
      background-color: #449d44;
      border-color: #255625;
    }
    .btn-success:hover {
      color: #fff;
      background-color: #449d44;
      border-color: #398439;
    }
    .btn-success:active,
    .btn-success.active,
    .open > .dropdown-toggle.btn-success {
      color: #fff;
      background-color: #449d44;
      border-color: #398439;
    }

    .btn-danger {
      color: #fff;
      background-color: #d9534f;
      border-color: #d43f3a;
      padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    }
    .btn-danger:focus,
    .btn-danger.focus {
      color: #fff;
      background-color: #c9302c;
      border-color: #761c19;
    }
    .btn-danger:hover {
      color: #fff;
      background-color: #c9302c;
      border-color: #ac2925;
    }
    .btn-danger:active,
    .btn-danger.active,
    .open > .dropdown-toggle.btn-danger {
      color: #fff;
      background-color: #c9302c;
      border-color: #ac2925;
    }

    .btn-warning {
      color: #fff;
      background-color: #f0ad4e;
      border-color: #eea236;
      padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    }
    .btn-warning:focus,
    .btn-warning.focus {
      color: #fff;
      background-color: #ec971f;
      border-color: #985f0d;
    }
    .btn-warning:hover {
      color: #fff;
      background-color: #ec971f;
      border-color: #d58512;
    }
    .btn-warning:active,
    .btn-warning.active,
    .open > .dropdown-toggle.btn-warning {
      color: #fff;
      background-color: #ec971f;
      border-color: #d58512;
    }
::-webkit-input-placeholder {
    color: rgba(0,0,0,0.7) !important;
}




.sticky-table-table{
    widows: 100% !important;
}

i{
    color: black !important;
}

.iFUdnr{
    width:100% !important;
}

table{
    border-spacing: 0px !important;
    border-collapse: collapse !important;
}

.scrolling.dimmable.dimmed>.dimmer{
    overflow: hidden !important;
}

.ui.modal .scrolling.content{
    max-height: calc(80vh) !important;
}

.modals.dimmer .ui.scrolling.modal{
    max-height: calc(95vh) !important;
    margin-top: 1% !important;
}

::-webkit-input-placeholder {
    color: rgba(0,0,0,0.7) !important;
} 


#conversion thead th { 
    z-index: 999 !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

.conversion thead th { 
    position: -webkit-sticky !important; 
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

#conversion {
    width: 99% !important;
    border: 3pt solid rgb(73, 82, 133) !important;
}

#conversion tr{
    border-bottom: 3pt solid rgb(73, 82, 133) !important;
}

#conversion td {
    vertical-align: middle !important;
}

#conversion td:first-child:hover, .cvtable td:nth-child(2):hover, .cvtable td:nth-child(3):hover, .cvtable td:nth-child(4):hover{
    background: none !important;
    color:black !important;
    cursor: auto !important;
}

.cvtable td:hover{
    cursor: pointer !important;
    background: rgb(0,181,173) !important;
    color: white !important;
}

#conversion td:active{
    zoom: 0.95 !important;
    background: rgb(73, 82, 133) !important;
}

#conversion td:nth-child(2):active{
    zoom: 1 !important;
    background: rgb(255, 255, 255) !important;
}

.conversion td{
    text-align: center !important;
}

.masterDiv .divBucket{
    overflow-x: scroll !important;
    overflow-y: scroll !important;
    max-height: 100vh !important;
    margin-bottom: 2% !important;
}

 /* width */
 .masterDiv::-webkit-scrollbar {
    background: rgb(73, 82, 133) !important;
    height: 12px !important;
  }

  .firstR{
    color: white !important;
  }

  .cvtable td:first-child{
    background: rgb(73, 82, 133, 1) !important;
 }

 .cvtable td:first-child:hover{
    background: rgb(73, 82, 133, 0.7) !important;
 }

 .cvtable td:first-child:hover .firstR{
    color: black !important;
 }

 .cvtable td:first-child label{
    font-weight: bold !important;
 }

 
.conversion thead th:first-child {
    width: 10% !important;
}

.conversion thead th:nth-child(2) {
    width: 22% !important;
}

.conversion thead th:nth-child(3) {
    width: 28% !important;
}

.conversion thead th:nth-child(5), .conversion thead th:nth-child(4) {
    width: 12% !important;
}

.cvtable tr:nth-child(1) td:first-child,.cvtable tr:nth-child(1),.cvtable tr:nth-child(1):hover td{
    background:rgb(37, 40, 57) !important;
    color:white !important;
}

.cvtable tr:nth-child(1) span, .cvtable tr:nth-child(1):hover span{
    color:coral !important;
}

.cvtable tr:nth-child(1) td:first-child label{
    color: rgb(73, 82, 133, 1) !important;
}

.cvtable tr:nth-child(1):hover td:first-child label{
    color: rgb(37, 40, 57) !important;
}

.cvtable tr:nth-child(1) td .laButton, .cvtable tr:nth-child(1) td:first-child label{
    display:none !important;
}

#conversion tr:first-child td:active{
    zoom: 1 !important;
    background: rgb(37, 40, 57) !important;
}

.cvtable tr:first-child td { 
    z-index: 1000 !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    background: rgb(37, 40, 57) !important;
    color: white !important;
    top: 7% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

.ui.fixed.table td, .ui.fixed.table th{
    white-space:pre-wrap !important;
}

.divBucket .dropdown{
    width: 20vw !important;
}

.divBucket .dropdown i{
    text-align: right !important;
    padding-top: 4% !important;
}

.divBucket .row .checkbox label {
    margin-top: 3% !important;
    font-size: larger !important;
    color: black !important;
}

.divBucket .col-sm-4 button{
    background-color: #17a2b8 !important;
    color: white !important;
}

.divBucket .col-sm-4 button:hover{
    color: white !important;
}

.menu{
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    width: 100% !important;
    height: 75vh !important;
}

.animated .bounceInLeft{
    width: 100vw !important;
}

.menu::-webkit-scrollbar-track{
    border-radius: 100pt !important;
}

.menu::-webkit-scrollbar-thumb{
    border-radius: 100pt !important;
}

.radioForm label{
    color: white !important;
}

.sticky-table-table{
    widows: 100% !important;
}

.popup-content{
    border: 0.3rem solid;
    border-image: linear-gradient(45deg, rgb(0,143,104), rgb(250,224,66)) 1 !important;
    padding: 1rem !important;
    background-color: teal !important;
    width: 19rem !important;
    z-index:1000 !important;
}

.popup-content div,.popup-content div b{
    color: white !important;
}

.trigger{
    border:none !important;
    padding: 0.58rem;
    border-radius: 0.3rem !important;
    margin-left: 2.35rem !important;
}

.trigger {
    border-color: rgb(73, 82, 133) !important;
    font-weight: bold !important;
    color: white;
    background-color: #00b5ad !important;
    opacity: 1;
    -webkit-animation: fade 2s linear;
            animation: fade 2s linear;
}

@-webkit-keyframes fade {
  0% { opacity: 0 }
  50% { opacity: 1 }
}

@keyframes fade {
  0% { opacity: 0 }
  50% { opacity: 1 }
}

.checkbox label{
    color: white !important;
}

i{
    color: black !important;
}

.iFUdnr{
    width:100% !important;
}

table{
    border-spacing: 0px !important;
    border-collapse: collapse !important;
}

.scrolling.dimmable.dimmed>.dimmer{
    overflow: hidden !important;
}

.ui.modal .scrolling.content{
    max-height: calc(80vh) !important;
}

.modals.dimmer .ui.scrolling.modal{
    max-height: calc(95vh) !important;
    margin-top: 1% !important;
}

::-webkit-input-placeholder {
    color: rgba(0,0,0,0.7) !important;
} 

#conversion thead th {
    font-size: 85% !important; 
    z-index: 999 !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

.conversion thead th { 
    position: -webkit-sticky !important; 
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

#conversion {
    width: 99% !important;
    border: 3pt solid rgb(73, 82, 133) !important;
}

#conversion tr{
    border-bottom: 3pt solid rgb(73, 82, 133) !important;
}

#conversion td {
    vertical-align: middle !important;
}

#conversion td:first-child:hover, .cvtable td:nth-child(2):hover, .cvtable td:nth-child(3):hover, .cvtable td:nth-child(4):hover{
    background: none !important;
    color:black !important;
    cursor: auto !important;
}

.cvtable td:hover{
    cursor: pointer !important;
    background: rgb(0,181,173) !important;
    color: white !important;
}

#conversion td:active{
    zoom: 0.95 !important;
    background: rgb(73, 82, 133) !important;
}

#conversion td:nth-child(2):active{
    zoom: 1 !important;
    background: rgb(255, 255, 255) !important;
}

.conversion td{
    text-align: center !important;
}

.masterDiv{
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    max-height: 100vh !important;
}

 /* width */
 .masterDiv::-webkit-scrollbar {
    height: 12px !important;
  }


  .firstR{
    color: white !important;
  }

  .cvtable td:first-child{
    background: rgb(73, 82, 133, 1) !important;
 }

 .cvtable td:first-child:hover{
    background: rgb(73, 82, 133, 0.7) !important;
 }

 .cvtable td:first-child:hover .firstR{
    color: black !important;
 }

 .cvtable td:first-child label{
    font-weight: bold !important;
 }

 
.conversion thead th:first-child {
    width: 10% !important;
}

.conversion thead th:nth-child(2) {
    width: 22% !important;
}

.conversion thead th:nth-child(3) {
    width: 28% !important;
}

.conversion thead th:nth-child(5), .conversion thead th:nth-child(4) {
    width: 12% !important;
}

.cvtable tr:nth-child(1) td:first-child,.cvtable tr:nth-child(1),.cvtable tr:nth-child(1):hover td{
    background:rgb(37, 40, 57) !important;
    color:white !important;
}

.cvtable tr:nth-child(1) td,.cvtable tr:nth-child(1) label{
    color:white !important;
}

.cvtable tr:nth-child(1) span, .cvtable tr:nth-child(1):hover span{
    color:coral !important;
}

.cvtable tr:nth-child(1):hover td,.cvtable tr:nth-child(1):hover label{
    color:white !important;
}

.cvtable tr:nth-child(1) td:first-child label{
    color: rgb(73, 82, 133, 1) !important;
}

.cvtable tr:nth-child(1):hover td:first-child label{
    color: rgb(37, 40, 57) !important;
}

.cvtable tr:nth-child(1) td .laButton, .cvtable tr:nth-child(1) td:first-child label{
    display:none !important;
}

#conversion tr:first-child td:active{
    zoom: 1 !important;
    background: rgb(37, 40, 57) !important;
}

.cvtable tr:first-child td { 
    z-index: 1000 !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    background: rgb(37, 40, 57) !important;
    color: white !important;
    top: 7% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

.description .ui.fixed.table td, .ui.fixed.table th{
    white-space:pre-wrap !important;
}

.mainDivCV .clndr-calendars-wrapper, 
.mainDivCV .clndr-control, 
.mainDivCV .clndr-days, 
.mainDivCV .clndr-button-today{
    background: #fff !important;
}

.mainDivCV .clndr-calendars-wrapper{
    width: 102% !important;
    margin: -1% 0% -1% -1% !important;
}

.mainDivCV .clndr-control{
    padding: 3% 2% 0% 2% !important;
}

.mainDivCV .clndr-button-today{
    width: 102% !important;
    margin: -1% 0% -1% -1% !important;
}
.c{
background-color: #56bfb5;
border-color: #56bfb5;
color: #fff;
/* padding: 5px; */
border-radius: 3px;
font-size: 14px;
font-weight: bold;
}

body{
    overflow: hidden !important;
}

::-webkit-scrollbar{
    width: 10pt !important;
}

::-webkit-scrollbar-thumb{
    border-radius: 0px !important;
    background-color: rgb(0,181,173) !important;
}

::-webkit-scrollbar-thumb:hover{
    background-color: rgba(0,181,173,0.8) !important;
}

::-webkit-input-placeholder {
    color: rgba(0,0,0,0.7) !important;
}

.dIGrPx .iFUdnr{
    width: 99% !important;
}

.sc-bdnylx .jMhaxE .sticky-table-table{
    width: 100% !important;
}

.sticky-table div{
    border: 1pt solid rgb(73, 82, 133) !important;
}

.sticky-table div {
    vertical-align: middle !important;
}

.dIGrPx .sc-AxiKw:not(:nth-last-child(-n+1)) .sc-AxirZ{
    border-bottom: 0px solid black !important;
    border-right: 0px solid #e5e5e5;
}

.conversion thead th { 
    position: -webkit-sticky !important; 
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

.description{
    padding: 1% !important;
}
::-webkit-input-placeholder {
    color: rgba(0,0,0,0.7) !important;
    
}

.ui.modal>.actions{
    padding: 2rem 1rem !important;
}

.ui.modal .scrolling.content{
    padding: 0px !important;
}

::-webkit-scrollbar-track{
    border-radius: 0px !important;
    background-color: rgba(255,255,255,0.6) !important;
}

.ui.modal>.close{
    padding: .525rem 0 0 0 !important;
    background: rgba(255,255,255,1) !important;
    border-radius: 100% !important;
    right: -0.4% !important;
}


th{
    text-align: center !important;
    width: 33.3% !important;
}

td{
    text-align: center !important;
}


.conversion thead th { 
    position: -webkit-sticky !important; 
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1.5% 0% 1.5% 0% !important;
}
.wid{
    width: 100%;
}
.ui.fluid.dropdown {
    width: 100% !important;
}

::-webkit-input-placeholder {
  color: rgba(0,0,0,0.7) !important;
} 
  .ReactTable .rt-thead.-header{
   background:#eb4d4b !important; color:white;  
    /* border:1px solid  black; */

  }
  
 .ReactTable .rt-thead.-headerGroups {
    background:#eb4d4b !important; color:white;
    border:1px solid black;
  } 
.ReactTable .rt-thead .rt-th{
    border-right: 1px solid black;
}
.ReactTable{
    border: 1px solid black;
}




 /* th{
  border: 3pt solid rgb(132, 133, 138) !important;
} */
.radioForm label{
  color: white !important;
}

.sticky-table-table{
  width: 100% !important;
}

.checkbox label{
  color: white !important;
}

i{
  color: black !important;
}

.iFUdnr{
  width:100% !important;
}

table{
  border-spacing: 0px !important;
  border-collapse: collapse !important;
}

.scrolling.dimmable.dimmed>.dimmer{
  overflow: hidden !important;
}

.ui.modal .scrolling.content{
  max-height: calc(80vh) !important;
}

.modals.dimmer .ui.scrolling.modal{
  max-height: calc(95vh) !important;
  margin-top: 1% !important;
}

::-webkit-input-placeholder {
  color: rgba(0,0,0,0.7) !important;
} 


#conversion thead th { 
  position: -webkit-sticky !important; 
  position: sticky !important;
  background: rgb(37, 40, 57) !important;
  color: white !important;
  
}


table.dataTable thead {
  cursor: pointer;
  background-color: #313f45;
  color: #fff;
}

th {
  text-align: center !important;
  width: auto !important;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone {
  text-align: Right !important;
}

.ui.table:last-child {
  margin-bottom: 0;
  margin-top: 0;
}

#txtareaClaim::-webkit-input-placeholder {
  font-size: 30px;
  text-align: center;
  padding-top: 16%;
}

#txtareaClaim::placeholder {
  font-size: 30px;
  text-align: center;
  padding-top: 16%;
}

.sweetAlertWidth50 {
  width: 50% !important;
}


.MuiOutlinedInput-input {
  padding: 10.5px 14px !important;
}

/* 
.ui.attached.button {
  position: relative;
  display: inline-block;
  margin: 0;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) !important;
}

.ui[class*="right floated"].header {
  float: right;
  margin-top: -4%;
  margin-left: 0.5em;
} */

.modals.dimmer .ui.scrolling.modal {
  max-height: calc(95vh) !important;
  margin-top: 1% !important;
  margin-left: 30%;
}

.ui.dropdown .menu>.item {
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  height: auto;
  text-align: left;
  border-top: none;
  line-height: 1em;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.78571429rem 1.14285714rem !important;
  font-size: 12px;
  text-transform: none;
  font-weight: 400;
  box-shadow: none;
  -webkit-touch-callout: none;
}

.ui.search {
  font-size: 12px;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
  margin-top: 2vh;
  float: left;
}

.ImageUploadCard-root-41 {
  width: 48vw;
  display: inline-block;
  align-items: flex-end;
  justify-content: center;
  background-color: #fff;
  margin-top: 1vh;
}

.ui.modal>.close {
  padding: 3.525rem 0 0 0 !important;
  background: rgba(255, 255, 255, 1) !important;
  border-radius: 100% !important;
  right: -0.4% !important;
  font-size: 3.25em;
  margin-right: 1em;
}

.modals.dimmer .ui.scrolling.modal {
  max-height: calc(95vh) !important;
  margin-top: 1% !important;
  margin-left: 30%;
  width: 80vw;
  margin-left: 10vw;
}

.ui.segment {
  position: relative;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 1rem 0;
  padding: 0em 0em;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

img {
  vertical-align: middle !important;
  border-style: none !important;
  width: 100px;
  height: 100px;
}

/* .zoom {
  padding: 50px;
  background-color: green;
  transition: transform 0.2s;
  width: 25px;
  height: 25px;
  margin: 0 auto;
} */

img#updateImgrcbLogo:hover {
  -webkit-transform: scale(5) !important;
  transform: scale(5) !important;
  z-index: 99999;
  position: relative;
}

/* .ui.table thead th {
  cursor: auto;
  background: #313f45;
  text-align: inherit;
  color: #fff;
  padding: 0.92857143em 0.78571429em;
  vertical-align: inherit;
  font-style: none;
  font-weight: 700;
  text-transform: none;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  border-left: none;
  white-space: nowrap;
} */

.ui.table.dataTable thead th {
  cursor: pointer;
  white-space: nowrap;
  border-left: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.table.dataTable thead th:first-child {
  border-left: none;
}

.ui.table.dataTable thead .sorting,
.ui.table.dataTable thead .sorting_asc,
.ui.table.dataTable thead .sorting_desc,
.ui.table.dataTable thead .sorting_asc_disabled,
.ui.table.dataTable thead .sorting_desc_disabled,
.ui.table.dataTable thead .sorting:hover,
.ui.table.dataTable thead .sorting_asc:hover,
.ui.table.dataTable thead .sorting_desc:hover,
.ui.table.dataTable thead .sorting_asc_disabled:hover,
.ui.table.dataTable thead .sorting_desc_disabled:hover {
  -webkit-user-select: none;
  user-select: none;
}

.ui.table.dataTable thead th:after {
  display: none;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "";
  height: 1em;
  width: auto;
  opacity: 0.8;
  margin: 0em 0em 0em 0.5em;
  font-family: "Icons";
}

.ui.table.dataTable thead th.sorting_asc:after {
  /*content: '\f0d8';*/
  content: "\F160";
}

.ui.table.dataTable thead th.sorting_desc:after {
  /*content: '\f0d7';*/
  content: "\F161";
}

.ui.table.dataTable thead th.sorting:after {
  content: "\F0DC";
  opacity: 0.2;
}

/* Hover */

.ui.table.dataTable th.disabled:hover {
  cursor: auto;
  color: rgba(40, 40, 40, 0.3);
}

.ui.table.dataTable thead th:hover {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.8);
}

/* Sorted */

.ui.table.dataTable thead .sorting_asc,
.ui.table.dataTable thead .sorting_desc,
.ui.table.dataTable thead .sorting_asc_disabled,
.ui.table.dataTable thead .sorting_desc_disabled {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
}

.ui.table.dataTable thead .sorting:after,
.ui.table.dataTable thead .sorting_asc:after,
.ui.table.dataTable thead .sorting_desc:after,
.ui.table.dataTable thead .sorting_asc_disabled:after,
.ui.table.dataTable thead .sorting_desc_disabled:after {
  display: inline-block;
}

/* Sorted Hover */

.ui.table.dataTable thead .sorting_asc:hover,
.ui.table.dataTable thead .sorting_desc:hover,
.ui.table.dataTable thead .sorting_asc_disabled:hover,
.ui.table.dataTable thead .sorting_desc_disabled:hover {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
}

.dataTables_length select {
  background: #fff none repeat scroll 0 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.285714rem;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  display: inline-block;
  line-height: 1.2142em;
  min-height: 0.714286em;

  outline: 0 none;
  padding: 0.3em;
  -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
  transition: box-shadow 0.1s ease 0s, width 0.1s ease 0s;
  white-space: normal;
  word-wrap: break-word;
}

.dataTables_wrapper .dataTables_filter {
  text-align: right;

  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  position: relative;
}

.dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em;
}

.dataTables_wrapper .dataTables_info {
  clear: both;
  padding-top: 0.755em;
}

.dataTables_filter input {
  background: #fff none repeat scroll 0 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.285714rem;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
  flex: 1 0 auto;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  height: 1em;
  margin: 0;
  max-width: 100%;
  outline: 0 none;
  padding: 0.4em;
  text-align: left;
  transition: background-color 0.1s ease 0s, box-shadow 0.1s ease 0s,
    border-color 0.1s ease 0s;
}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.7) !important;
}
#tblSM tr:last-child {
    font-weight: bold !important;
    background: gainsboro !important;
}

#cell_table tr:last-child td {
    font-weight: bolder !important;
    background: gainsboro !important;
}

#cell_table1 tr:last-child td {
    font-weight: bolder !important;
    background: gainsboro !important;
}


.thwidth {
    width: 14.2% !important;
}


.outer {
    width: 100%;
    text-align: center;
}

.inner {
    position: absolute;
    left: 50%;
}

/* .conversion thead th { 
    position: sticky !important;
    background: rgb(20, 23, 37) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

#conversion {
    width: 99% !important;
    border: 3pt solid rgb(20, 23, 37) !important;
}

#conversion tr{
    border-bottom: 3pt solid rgb(20, 23, 37) !important;
}

#conversion td {
    vertical-align: middle !important;
}

#conversion td:first-child:hover, #conversion td:last-child:hover{
    background: none !important;
    color:black !important;
    cursor: auto !important;
}

#conversion td:hover{
    cursor: pointer !important;
    background: rgb(0,181,173) !important;
    color: white !important;
}

#conversion td:active{
    zoom: 0.95 !important;
    background: rgb(20, 23, 37) !important;
} */

.cvvtable td:hover {
    cursor: pointer !important;
    background: rgb(0, 181, 173) !important;
    color: white !important;
}

#cell_table td {
    text-align: center !important;
}

.ui.modal>.close {
    font-size: 2.25em !important;
}


#cell_table thead th {
    z-index: 999 !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

#cell_table1 td {
    text-align: center !important;
}


#cell_table1 thead th {
    z-index: 999 !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}


.nrprewrap td {
    white-space: nowrap !important;
}

.nrprewrap b {
    white-space: nowrap !important;
}

.masterDiv .segment {
    height: 9vh !important;
    white-space: pre-wrap !important;
}

.masterDiv .segment span {
    word-wrap: break-word !important;
    white-space: pre-wrap !important;
}

.ui.fixed.table td,
.ui.fixed.table th {
    white-space: inherit !important;
}

.filtering {
    margin-left: 0% !important;
}

.filtering span {
    font-size: 85% !important;
}

.divvipnj {
    position: absolute !important;
    width: 98.5vw !important;
    height: 100vh !important;
    background: rgba(0, 0, 0, 0.5) !important;
    z-index: 1000 !important;
    margin-left: 1.5vw !important;
}

.divvipnj1 {
    margin: 10% 20% !important;
    background: rgba(255, 255, 255, 1) !important;
    height: 61vh !important;
    border-radius: 0pt 0pt !important;
}

.divvipnj1 .field {
    margin-top: 1% !important;
}

.divvipnj1 .field input {
    width: 14.7vw !important;
}

.btnClose {
    font-size: 90% !important;
    position: absolute !important;
    background: darkred !important;
    border-radius: 1pt !important;
    margin: -2.3% -1% -2% 0.7% !important;
}

.btnClose:active {
    opacity: 0.7 !important;
}

.btnView {
    margin-left: 1% !important;
}

.divvipnj1 .clndr-days {
    background-color: white !important;
}

.divvipnj1 .ui.fluid.button {
    margin-top: 0px !important;
}

.divvipnj1 #conversion {
    width: 100% !important;
}

.vipnj span {
    color: white !important;
}

.vipnj button {
    background-color: grey !important;
}

.vipnj button:active {
    background-color: teal !important;
}

.njvip {
    text-align: center !important;
}


.njvip td {
    text-align: center !important;
}

.description .ui.fixed.table td,
.ui.fixed.table th {
    white-space: normal !important;
}


#conversion thead th {
    z-index: 1 !important;
}
/* html{
    overflow: hidden;
} */

body{
    overflow-x: hidden;
    overflow-y: hidden !important;
}

.MuiDrawer-root .MuiTypography-root{
    color: black !important;
    background:transparent !important;
}

.selhov{
    background-color: white !important;
    color: black !important;
}

.selhov:hover{
    background-color: #333 !important;
    color: white !important;
}

.lihov{
    color: white;
    background-color: grey;
}

.lihov:hover{
    color: white !important;
    background-color: #333 !important;
}

.divIdToPrintDesktop{
    flex: 1 1;
    height: 100vh;
    display: flex;
    background-color: #fff;
    flex-direction: row;
    background-repeat: no-repeat;
    background-size: 180vh;
}

@media only screen and (min-width:992px){
    .SearchBoxItemDesktop{
        position: absolute !important;
        margin-top: 10% !important;
        z-index: 1000 !important;
    }
}

@media only screen and (min-width:300px) and (max-width:450px){
    .MuiContainer-maxWidthLg{
        display: block !important;
    }
    .iimglogg{
        width: 25%;
    }
    body{
        overflow-x: hidden;
        overflow-y: scroll !important;
        /* overflow-y: hidden !important; */
    }
    .divIdToPrintMobile{
        flex: 1 1;
        height: 120vh;
        display: flex;
        background-color: #333;
        flex-direction: row;
        /* background-image: url("./q_img.png"); */
    }
    .divTopTenMobile{
        margin-left: 20%;
        margin-top: 50%;
        border-radius: 3px;
        /* position: absolute; */
        /* left: 150px; */
        display: flex;
        height: 42vh;
        background-color: #bdbebf;
        width: 90vw;
        flex-direction: column;
    }
    .MuiDialog-paperScrollPaper{
        width: 100% !important;
    }
    .divOpenCredMobile{
        display: flex;
        flex-direction: row;
        /* padding: 50px; */
    }
    .txtEmailMobile{
        margin: 5% 5% 0% 5% !important;
        width: 90% !important;
    }
    .btnEmailMobile{
        margin: 5% 5% 5% 5% !important;
        width: 90% !important;
    }
    .modalQuizMobile{
        height: 90vh;
        flex-direction: column;
        background-color: #fafafa;
        width: 100vw;
        overflow: scroll;
    }
    .submitQuestionMobile{
        width: 50% !important;
        margin-top: 15% !important;
    }
    .xHRlineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 200% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
    .xHRClineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 90% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
    .divSearchBoxMobile{
        display: flex;
        flex-direction: row;
        margin: 5% 2.5% 10% 2.5% !important;
        padding-left: 10%;
        padding-top: 50%;
        /* background-color:#fff; */
        /* justify-content: center; */
        /* flex-direction:column; */
        /* align-items: center; */
    }
}

@media only screen and (min-width:450px) and (max-width:800px){
    .MuiContainer-maxWidthLg{
        display: block !important;
    }
    .iimglogg{
        width: 20%;
    }
    body{
        overflow-x: hidden;
        overflow-y: scroll !important;
        /* overflow-y: hidden !important; */
    }
    .divIdToPrintMobile{
        flex: 1 1;
        height: 140vh;
        display: flex;
        background-color: #333;
        flex-direction: row;
        /* background-image: url("./q_img.png"); */
    }
    .divTopTenMobile{
        margin-left: 20%;
        margin-top: 40%;
        border-radius: 3px;
        /* position: absolute; */
        /* left: 150px; */
        display: flex;
        height: 42vh;
        background-color: #bdbebf;
        width: 90vw;
        flex-direction: column;
    }
    .MuiDialog-paperScrollPaper{
        width: 100% !important;
    }
    .divOpenCredMobile{
        display: flex;
        flex-direction: row;
        /* padding: 50px; */
    }
    .txtEmailMobile{
        margin: 5% 5% 0% 5% !important;
        width: 90% !important;
    }
    .btnEmailMobile{
        margin: 5% 5% 5% 5% !important;
        width: 90% !important;
    }
    .modalQuizMobile{
        height: 90vh;
        flex-direction: column;
        background-color: #fafafa;
        width: 100vw;
        overflow: scroll;
    }
    .submitQuestionMobile{
        width: 50% !important;
        margin-top: 15% !important;
    }
    .xHRlineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 200% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
    .xHRClineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 90% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
}

@media only screen and (min-width:768px) and (max-width:992px){
    .MuiContainer-maxWidthLg{
        display: block !important;
    }
    .iimglogg{
        width: 15%;
    }
    body{
        overflow-x: hidden;
        overflow-y: scroll !important;
        /* overflow-y: hidden !important; */
    }
    .divIdToPrintMobile{
        flex: 1 1;
        height: 140vh;
        display: flex;
        background-color: #333;
        flex-direction: row;
        /* background-image: url("./q_img.png"); */
    }
    .divTopTenMobile{
        margin-left: 20%;
        margin-top: 40%;
        border-radius: 3px;
        /* position: absolute; */
        /* left: 150px; */
        display: flex;
        height: 42vh;
        background-color: #bdbebf;
        width: 90vw;
        flex-direction: column;
    }
    .MuiDialog-paperScrollPaper{
        width: 100% !important;
    }
    .divOpenCredMobile{
        display: flex;
        flex-direction: row;
        /* padding: 50px; */
    }
    .txtEmailMobile{
        margin: 5% 5% 0% 5% !important;
        width: 90% !important;
    }
    .btnEmailMobile{
        margin: 5% 5% 5% 5% !important;
        width: 90% !important;
    }
    .modalQuizMobile{
        height: 90vh;
        flex-direction: column;
        background-color: #fafafa;
        width: 100vw;
        overflow: scroll;
    }
    .submitQuestionMobile{
        width: 50% !important;
        margin-top: 15% !important;
    }
    .xHRlineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 200% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
    .xHRClineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 90% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
}

::-webkit-scrollbar {
    width: 2%;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #f50057; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
    /* cursor: pointer !important; */
  }

.logoDivDesktop{
    /* width: '20vw'; */
    flex: 4 1;
    background-color: #ededed;
    display: flex;
    justify-content: center;
    padding: 10%;
    /* alignItems: 'center'; */
}

.logoDivMobile{
    /* width: '20vw'; */
    flex: 4 1;
    /* backgroundColor: '#eeeeee'; */
    display: flex;
    justify-content: center;
    padding: 1%;
    /* alignItems: 'center'; */
}

.logoDivImgDesktop{
    margin-left: 10%;
    margin-top: 5%;
    width:95%;
    height:8%;
}

.logoDivImgMobile{
    margin-top: 25%;
    width:90px !important;
    height:30px;
}

.anchorlogoDivImgMobile{
    /* margin-left: 23%; */
    float: right;
}

.rightAreaDesktop{
    /* width: '90vw'; */
    flex: 10 1;
    display: flex;
    /* justify-content: 'center', */
    flex-direction: column;
    align-items: center;
}

.rightAreaMobile{
    /* width: '90vw'; */
    flex: 10 1;
    display: flex;
    /* justify-content: 'center', */
    flex-direction: column;
    align-items: center;
}

.rightHeaderDesktop{
    height: 10vh;
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
}

.rightfooterDesktop{
    height: 7vh;
    width: 100vw;
    background-color: #333;
    display: flex;
    
    flex-direction: row;
    align-items: flex-end;
    position: fixed;
    bottom: -0px;
}
.rightfooterDesktop_contact{
    height: 7vh;
    width: 100vw;
    background-color: #333;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-end;
    position: fixed;
    bottom: -0px;
}

.rightHeaderMobile{
    /* display: none !important; */
    margin-left: -4%;
    margin-top: 20%;
    position: absolute;
    background-color: #333;
    /* background:rgba(0,0,0,0); */
    opacity: 1;
    z-index: 1000;
    height: 100vh;
    /* border:1px solid white; */
    width: 60vw;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}

.tblHeadMobile{
    padding: 5%;
    width: 100%;
    position: absolute;
    background-color: #333;
    color: white !important;
}

.tblHeadMobile h5{
    width: 100%;
    padding: 4%;
    /* background-color:#3498db ; */
    /* border:1px solid rgba(0,0,0,0.6) !important; */
}

.btactive:hover{
    /* background-color:#2ecc71 ; */
    background-color:#2ecc71 !important;
}

.btactive{
    background-color:#3498db !important;
}

.fa-bars{
    width: 100% !important;
}

.fa-times{
    display: none;
    padding-left: 18% !important;
    /* margin-right: 10% !important; */
}

.homeButtonDesktop{
    font-weight: bold;
    cursor: pointer;
    margin-left: 4%; 
    font-size: 100%; 
    display: flex; 
    font-family: Arial, Helvetica, sans-serif; 
    color: #fff;
}

.homeButtonMobile{
    font-weight: bold;
    cursor: pointer;
    margin-left: 4%; 
    font-size: 100%; 
    display: flex; 
    font-family: Arial, Helvetica, sans-serif; 
    color: #fff;
}

.homeButtonDesktop:hover{
    color: #74b9ff;
}

.homeButtonMobile:hover{
    color: #74b9ff;
}

.dashButtonDesktop{
    margin-right: 20px;
    font-weight: bold;
    cursor: pointer;
    font-size: 100%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
}

.dashButtonMobile{
    font-weight: bold;
    cursor: pointer;
    margin-left: 3%;
    font-size: 100%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
}

.dashButtonDesktop:hover{
    color: #74b9ff;
}

.dashButtonMobile:hover{
    color: #74b9ff;
}

.divQubitImgDesktop{
    
    height: 20vh;
    background-color: #fff;
    justify-content: center;
    /* flex-direction:'column'; */
    padding-left: 1%;
    align-items: center;
}

.divQubitImgMobile{
    height: 20vh;
    background-color: #333;
    justify-content: center;
    /* flex-direction:'column'; */
    padding-left: 20%;
    padding-top: 10%;
    align-items: center;
}

.QubitImgDesktop{
    width:160px;
    height:55px;
}

.QubitImgMobile{
    margin-left: 21%;
    width:130px;
    height:45px;
}

.QubitHeadlineDesktop{
    color: #fff;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 'bold';
    font-size: 110%;
    margin-top: 3%;
}

.QubitHeadlineMobile{
    color: #fff;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 'bold';
    font-size: 150%;
    margin-top: 10%;
}

.QubitParaDesktop{
    text-align: center;
    opacity: 0.7;
    font-size: 90%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
    width: 83%;
    font-weight: 500;
    font-size: larger;
}

.QubitParaMobile{
    position: absolute !important;
    margin-top: 40%;
    padding: 3%;
    text-align: center;
    font-size: 110%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color:white;
}

.divSearchBoxDesktop{
    display: flex;
    flex-direction: row;
    margin-left:-22.5% ;
    padding-left: 1%;
    /* background-color:#fff; */
    /* justify-content: center; */
    /* flex-direction:column; */
    /* align-items: center; */
}

.divSearchBoxMobile{
    display: flex;
    flex-direction: row;
    margin: 2.5% 2.5% 10% 2.5%;
    padding-left: 10%;
    padding-top: 50%;
    /* background-color:#fff; */
    /* justify-content: center; */
    /* flex-direction:column; */
    /* align-items: center; */
}

.SearchBoxDesktop{
    font-weight: bold;
    position: absolute;
    /* margin:5px; */
    margin-left: -30%;
    margin-top:0.3%;
    justify-content:flex-end;
    font-size: 90%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 29%;
}

.SearchBoxMobile{
    font-weight: bold;
    position: absolute;
    /* margin:5px; */
    margin-left: -35%;
    margin-top:3%;
    justify-content:flex-end;
    font-size: 100%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 75%;
}

.divSearchBarDesktop{
    display: flex;
    position: relative;
    left: 100px;
    z-index: 1000;
    flex-direction: column;
}

.divSearchBarMobile{
    margin-left: 20%;
    display: flex;
    position: absolute;
    z-index: 999;
    flex-direction: column;
}

.SearchBarDesktop{
    padding-left: 1.5%;
    background-color: #eeeeee;
    height: 5.2vh;
    border-radius: 3px;
    font-size: 18px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 500px;
    border: 1px solid #cccccc;

}
.ccc{
    border: 1px solid #cccccc;
    height: 30px;
    padding: 10px;
  

}
.SearchBarMobile{
    padding-left: 5px;
    background-color: #8e8f91;
    height: 30px;
    border-radius: 3px;
    font-size: 14px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 45%;
    margin-top: 7%;
    margin-left: -19%;
}

.SearchBoxItemDesktop{
    background-color: #eeeeee;   
    width: 500px;
   
}

.SearchBoxItemMobile{
    background-color: #fff;
}

.inputSearchDesktop{
    width: 500px !important;
}

.inputSearchMobile{
    width: 600px !important;
}

.inputSearchDesktop input{
    font-size: 92%;
    padding-left: 0%;
    padding-right: 2%;
}

.inputSearchMobile input{
    font-size: 95%;
    padding-left: 0%;
    padding-right: 2%;
}

.divSearchBarMobile span+span{
    width: 37.8% !important;
    margin-left: 1% !important;
}

.selcourse input{
    text-align: center !important;
}

.numques input{
    text-align: center !important;
}

.listSearchDesktop{
    cursor: pointer;
    border: 1px solid #cccccc;
    margin: 0px;
    padding: 4px;
    font-size: 14px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
}

.listSearchMobile{
    cursor: pointer;
    /* border: 1px solid #eeeeee; */
    width: 119%;
    margin-left: -18.9%;
    padding: 4px;
    font-size: 12px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
}

.listSearchDesktop:hover{
    color:#eeeeee;
    background-color: #333;
}

.listSearchMobile{
    color:#333;
    background-color: white;
}

.listSearchMobile:hover{
    color:#eeeeee;
    background-color: #333;
}

.divSelectNumberDesktop{
    display: flex;
    margin: 10px;
    margin-left: 100px;
}

.divSelectNumberMobile{
    z-index: -1;
    position: absolute;
    display: flex;
    margin-top: 12%;
    margin-left: 16%;
}

.SelectNumberDesktop{
    margin-top: 3%;
    font-size: 16px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
    opacity: 0.7;
}

.SelectNumberDesktop_tq{
    margin-top: 12px;
    position: absolute;
    left: -35px;
    font-size: 16px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
    opacity: 0.7;
}

.SelectNumberMobile{
    margin-top: 8%;
    margin-left: -40%;
    font-size: 14px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
}

.inputNumberDesktop{
    margin-top: 8px;
    margin-right: 5px;
    padding-left: 5px;
    justify-content: flex-start;
    background-color: #eeeeee;
    height: 25px;
    border-right: 40px;
    font-size: 16px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 40px;
}

.divSelectNumberMobile .inputNumberMobile{
    margin-top: 16%;
    margin-right: 5px;
    padding-left: 5px;
    margin-left: -30% !important;
    width: 10% !important;
    justify-content: flex-start;
    background-color: #fff;
    height: 25px;
    border-right: 3px;
    font-size: 10px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 30px;
}

.inputNumDesktop{
    width: 600;
}

.inputNumMobile{
    width: 600;
}

.inputNumDesktop input{
    border-radius: 5px;
    font-size: 16px;
    padding: 1%;
    font-weight: bold;
}

.divSelectNumberMobile .inputNumMobile input{
    font-size: 100%;
    padding: 1%;
    font-weight: bold;
}

.divGoButtonDesktop:hover{
    cursor: pointer;
}

.divSelectNumberMobile .divGoButtonMobile{
    margin-top: 14.5%;
}

.divSelectNumberMobile .divGoButtonMobile:hover{
    cursor: pointer;
}

.divTopTenDesktop{
    margin-left: 100px;
    margin-top: -1.5%;
    border-radius: 3px;
    position: relative;
    left: 150px;
    display: flex;
    height: 44vh;
    background-color: #bdbebf;
    width: 35vw;
    flex-direction: column;
}

.TopTenDesktop{
    margin: 3% 0% 2% 3%;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
}

.TopTenMobile{
    margin: 3% 0% 2% 3%;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
}

.listTopTenDesktop{
    /* width: 100vh; */
    margin: 0px;
    padding: 1% 0% 0% 2%;
    font-size: 12px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
}

.listTopTenMobile{
    padding: 1.5% 0% 0% 2%;
    font-size: 12px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
}

.listTopTenDesktop:hover{
    color:#34495e;    
    cursor: pointer;
    font-size: 12.5px;
}

.listTopTenMobile:hover{
    color:#34495e;
    cursor: pointer;
    font-size: 12.5px;
}

.divLastDesktop{
    /* width: 60; */
    flex: 2 1;
    display: flex;
    /* justifyContent: 'center'; */
    background-color: #333;
}

.divLastMobile{
    /* width: 60; */
    flex: 2 1;
    display: flex;
    /* justifyContent: 'center'; */
    background-color: #333;
}

.navbarMobile{
    height:8.5vh;
    /* padding-bottom: 20%; */
    background-color:#f3f3f3;
}

.divmainMobile{
    background-color:#333 !important;
}
.cc{
    text-align: right !important;
}
.ui.fluid.dropdown {
  width: 93%;
}
.inputSearchDesktop {
  width: 300px;
}
.SearchBoxItemDesktop {
  width: 69.5% !important;
  height: auto !important;
  overflow-y: scroll !important;
  max-height: 720% !important;
}

table {
  border-collapse: collapse;
  border: 1px solid #b0b0b0;
}
* {
  font-family: Gisha, Arial;
  font-size: 13px;
  color: #333;
  line-height: 18px;
}
td {
  text-align: Left !important;
}

.borderB td {
  border-color: #b0b0b0;
}
.borderB {
  border-bottom: 0 none;
  border-left: 0 none;
}

.inputSearchDesktop {
  width: 300px !important;
}
/* 
.ui.input {
  width: 100px;
} */

.ui.fluid.dropdown {
  width: 93%;
}

.inputSearchDesktop {
  width: 300px;
}

.SearchBoxItemDesktop {
  width: 17.6vw !important;
  height: auto !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  max-height: 650% !important;
  margin-top: 7.8vh !important;
}

.tdAuthor {
  text-align: right !important;
}

.tdAuthors {
  padding-right: 3% !important;
}

.tdDeclarate {
  padding-left: 1% !important;
}

.divbgIMG {
  /* background-size: 50% !important; */
  /* background-position: center !important;
  background-repeat: no-repeat !important;
  background-image: url("./LetterHead.jpeg"); */
  position: absolute;
  width: 1000px;
  margin-left: 7.2%;
  height: 100vh !important;
  z-index: -1 !important;
}

img:hover {
  -webkit-transform: none !important;
          transform: none !important;
}

.InvoiceTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.InvoiceTable td,
.InvoiceTable th {
  border: 1px solid #ddd;
  padding: 3px;
}

.InvoiceTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.InvoiceTable tr:hover {
  background-color: #ddd;
}

.InvoiceTable th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background-color: #34495e;
  color: white;
}

.box-Scroll {
  height: 70vh;
  width: 100%;
  overflow-y: scroll;
  padding: 20px;
  margin-bottom: 30px;
}

.activeDateSpan {
    border-bottom: 3px groove #000306 !important;
}

.custumDataTable {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.custumDataTable .custumRow {
    border: 1px solid #dee2e6 !important;
}

table#as-react-datatable td {
    padding: .75rem !important;
    vertical-align: top !important;
    border: 1px solid #dee2e6 !important;
}

.custumAutoCompleteList {
    position: absolute;
    border: 1px solid #d0cccc;
    background-color: white;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
}

.sweetAlertWidth {
    width: 98% !important;
}

.sweetAlertWidth30 {
    width: 30% !important;
}

.sweetAlertWidth50 {
    width: 50% !important;
}

path {
    color: red !important;
}
.popup{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    background-color:rgba(0, 0, 0, 0.22);
     display:flex;
     justify-content: center;
    align-items: center;
    z-index :99;
}

    .popup-inner{
position:relative;
padding:32px;
width:100%;
max-width: 640px;
background-color: #fff;

}
.popup-inner .close-btn{
    position: absolute;
    top:16px;
    right: 16px;
}
.accordion-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.accordion-header {
    background-color: #f1f1f1;
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.accordion-header::after {
    content: '\25B6';
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.accordion-header.active::after {
    -webkit-transform: translateY(-50%) rotate(90deg);
            transform: translateY(-50%) rotate(90deg);
}

.accordion-content {
    padding: 10px;
    display: none;
    background-color: #fff;
    transition: max-height 0.3s ease;
    overflow: hidden;
}

.accordion-content p {
    margin: 0;
}
.grid-container {
    display: grid;
    grid-template-columns: 300px 300px 100px 100px;
    grid-gap: 10px;
    
    padding: 10px;
  }
  
  .grid-container > div {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px 0;
    font-size: 14px;    
  }
  label
  {
      font-weight: bold;
      font-size: 15px;
  }
  
  .headerBackGround
  {
    background: radial-gradient(#007bff, #8ffbf6);
  }
  .panelBorder
  {
    border: 1px solid #eceaea;
    border-radius: 10px
  }
td:active {
  background: none !important;
}

.radioForm label {
  color: white !important;
}

.sticky-table-table {
  widows: 100% !important;
}

.SPradioDiv .checkbox:first-child label {
  color: rgb(73, 82, 133) !important;
}

.SPradioDiv .checkbox label {
  color: rgb(0, 181, 173) !important;
}

i {
  color: black !important;
}

.iFUdnr {
  width: 100% !important;
}

table {
  border-spacing: 0px !important;
  border-collapse: collapse !important;
}

.scrolling.dimmable.dimmed > .dimmer {
  overflow: hidden !important;
}

.ui.modal .scrolling.content {
  max-height: calc(80vh) !important;
}

.modals.dimmer .ui.scrolling.modal {
  max-height: calc(95vh) !important;
  margin-top: 1% !important;
}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.7) !important;
}

#cconversion thead tr:nth-child(2) th {
  font-size: 85% !important;
  z-index: 999 !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  background: rgb(73, 82, 133) !important;
  color: white !important;
  top: 5.5% !important;
  padding: 1.2% 0% 1.2% 0% !important;
  width: auto !important;
}

#cconversion thead tr:nth-child(1) th {
  font-size: 85% !important;
  z-index: 999 !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  background: rgb(73, 82, 133) !important;
  color: white !important;
  top: -0.5% !important;
  width: auto !important;
}

.cconversion thead th {
  position: -webkit-sticky !important;
  position: sticky !important;
  background: rgb(73, 82, 133) !important;
  color: white !important;
  top: -0.5% !important;
  padding: 1% 0% 1% 0% !important;
  width: auto !important;
}

#cconversion {
  width: 100% !important;
  border: 3pt solid rgb(73, 82, 133) !important;
}

#cconversion td {
  padding: 0.5% 0.1% 0.5% 0.1% !important;
  border: 0.5px solid rgb(73, 82, 133) !important;
}

#cconversion td:first-child {
  font-weight: bold !important;
}

#cconversion tr {
  border-bottom: 3pt solid rgb(73, 82, 133) !important;
}

#cconversion td {
  vertical-align: middle !important;
}

/* #cconversion td:first-child:hover, .cvtable td:nth-child(2):hover, .cvtable td:nth-child(3):hover, .cvtable td:nth-child(4):hover{
    color:black !important;
    cursor: auto !important;
} */

.cvtable tr td:first-child:hover {
  zoom: 1 !important;
  background: rgb(255, 255, 255) !important;
}

#cconversion tr td:first-child:active {
  background: rgb(255, 255, 255) !important;
}

#cconversion thead tr th:first-child {
  width: 12% !important;
}

/* #cconversion td:nth-child(1):hover
,#cconversion td:nth-child(3):hover,#cconversion td:nth-child(4):hover,#cconversion td:nth-child(5):hover
,#cconversion td:nth-child(7):hover,#cconversion td:nth-child(8):hover,#cconversion td:nth-child(9):hover
,#cconversion td:nth-child(11):hover,#cconversion td:nth-child(12):hover,#cconversion td:nth-child(13):hover
,#cconversion td:nth-child(15):hover,#cconversion td:nth-child(16):hover,#cconversion td:nth-child(17):hover
{
    cursor: auto !important;
    background: rgb(255,255,255) !important;
    color: black !important;
} */

#cconversion td:nth-child(1):active,
#cconversion td:nth-child(3):active,
#cconversion td:nth-child(4):active,
#cconversion td:nth-child(5):active,
#cconversion td:nth-child(7):active,
#cconversion td:nth-child(8):active,
#cconversion td:nth-child(9):active,
#cconversion td:nth-child(11):active,
#cconversion td:nth-child(12):active,
#cconversion td:nth-child(13):active,
#cconversion td:nth-child(15):active,
#cconversion td:nth-child(16):active,
#cconversion td:nth-child(17):active {
  zoom: 1 !important;
}

.cvtable td:hover {
  cursor: pointer !important;
  background: rgb(0, 181, 173) !important;
  color: white !important;
}

#cconversion td:active {
  zoom: 0.95 !important;
  background: rgb(0, 181, 173) !important;
}

#cconversion td {
  font-size: 90% !important;
}

.cconversion td {
  text-align: center !important;
}

.masterDiv {
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  max-height: 100vh !important;
}

/* width */
.masterDiv::-webkit-scrollbar {
  height: 12px !important;
}

.firstR {
  color: white !important;
}

.cvtable td:first-child {
  background: rgb(73, 82, 133, 1) !important;
}

.cvtable td:first-child:hover {
  background: rgb(73, 82, 133, 0.7) !important;
}

.cvtable td:first-child:hover .firstR {
  color: black !important;
}

.cvtable td:first-child label {
  font-weight: bold !important;
}

.cconversion tbody tr:last-child td {
  font-weight: bold !important;
}

.cconversion thead th:first-child {
  width: 10% !important;
}

.cconversion thead th:nth-child(2) {
  width: 15% !important;
}

.cconversion thead th:nth-child(3) {
  width: 25% !important;
}

.cconversion thead th:nth-child(4) {
  width: 20% !important;
}

.cconversion thead th:nth-child(5) {
  width: 15% !important;
}

.cconversion thead th:nth-child(6) {
  width: 15% !important;
}

.cvtable tr:nth-child(1) td:first-child,
.cvtable tr:nth-child(1),
.cvtable tr:nth-child(1):hover td {
  background: rgb(37, 40, 57) !important;
  color: white !important;
}

.cvtable tr:nth-child(1) td,
.cvtable tr:nth-child(1) label {
  color: white !important;
}

.cvtable tr:nth-child(1) span,
.cvtable tr:nth-child(1):hover span {
  color: coral !important;
}

.cvtable tr:nth-child(1):hover td,
.cvtable tr:nth-child(1):hover label {
  color: white !important;
}

.cvtable tr:nth-child(1) td:first-child label {
  color: rgb(73, 82, 133, 1) !important;
}

.cvtable tr:nth-child(1):hover td:first-child label {
  color: rgb(37, 40, 57) !important;
}

.cvtable tr:nth-child(1) td .laButton,
.cvtable tr:nth-child(1) td:first-child label {
  display: none !important;
}

.cvtable tr:first-child td {
  z-index: 1000 !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  background: rgb(37, 40, 57) !important;
  color: white !important;
  top: 7% !important;
  padding: 1.5% 0% 1.5% 0% !important;
  width: auto !important;
}

.description .ui.fixed.table td,
.ui.fixed.table th {
  white-space: pre-wrap !important;
}

.mainDivCV .clndr-calendars-wrapper,
.mainDivCV .clndr-control,
.mainDivCV .clndr-days,
.mainDivCV .clndr-button-today {
  background: #fff !important;
}

.mainDivCV .clndr-calendars-wrapper {
  width: 102% !important;
  margin: -1% 0% -1% -1% !important;
}

.mainDivCV .clndr-control {
  padding: 3% 2% 0% 2% !important;
}

.mainDivCV .clndr-button-today {
  width: 102% !important;
  margin: -1% 0% -1% -1% !important;
}

.popupasm {
  position: fixed;
  top: 60%;
  left: 60%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 80%; /* Set maximum width */
  width: auto; /* Auto width */
  max-height: 50%; /* Set maximum height */
  overflow-y: auto; /* Add scrollbar if content overflows */
}

.popup-contentasm {
  text-align: center;
  width: 400px !important;
  background-color: white !important;
}

.closeasm {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #888;
}

.popup-titleasm {
  margin-bottom: 10px;
  font-size: 18px;
  color: black !important;
}

.popup-detailsasm {
  margin-top: 20px;
}

.popup-itemasm {
  margin-bottom: 10px;
  color: black !important;
}

td:active {
    background: none !important;
}

.radioForm label {
    color: white !important;
}

.sticky-table-table {
    widows: 100% !important;
}

.SPradioDiv .checkbox:first-child label {
    color: rgb(73, 82, 133) !important;
}

.SPradioDiv .checkbox label {
    color: rgb(0, 181, 173) !important;
}


i {
    color: black !important;
}

.iFUdnr {
    width: 100% !important;
}

table {
    border-spacing: 0px !important;
    border-collapse: collapse !important;
}

.scrolling.dimmable.dimmed>.dimmer {
    overflow: hidden !important;
}

.ui.modal .scrolling.content {
    max-height: calc(80vh) !important;
}

.modals.dimmer .ui.scrolling.modal {
    max-height: calc(95vh) !important;
    margin-top: 1% !important;
}

::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.7) !important;
}

#cconversion thead tr:nth-child(2) th {
    font-size: 85% !important;
    z-index: 999 !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: 5.5% !important;
    padding: 1.2% 0% 1.2% 0% !important;
    width: auto !important;
}

#cconversion thead tr:nth-child(1) th {
    font-size: 85% !important;
    z-index: 999 !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    width: auto !important;
}

.cconversion thead th {
    position: -webkit-sticky !important;
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1% 0% 1% 0% !important;
    width: auto !important;
}

#cconversion {
    width: 100% !important;
    border: 3pt solid rgb(73, 82, 133) !important;
}

#cconversion td {
    padding: 0.5% 0.1% 0.5% 0.1% !important;
    border: 0.5px solid rgb(73, 82, 133) !important;
}

#cconversion td:first-child {
    font-weight: bold !important;
}

#cconversion tr {
    border-bottom: 3pt solid rgb(73, 82, 133) !important;
}

#cconversion td {
    vertical-align: middle !important;
}

/* #cconversion td:first-child:hover, .cvtable td:nth-child(2):hover, .cvtable td:nth-child(3):hover, .cvtable td:nth-child(4):hover{
    color:black !important;
    cursor: auto !important;
} */

.cvtable tr td:first-child:hover {
    zoom: 1 !important;
    background: rgb(255, 255, 255) !important;
}

#cconversion tr td:first-child:active {
    background: rgb(255, 255, 255) !important;
}

#cconversion thead tr th:first-child {
    width: 12% !important;
}

/* #cconversion td:nth-child(1):hover
,#cconversion td:nth-child(3):hover,#cconversion td:nth-child(4):hover,#cconversion td:nth-child(5):hover
,#cconversion td:nth-child(7):hover,#cconversion td:nth-child(8):hover,#cconversion td:nth-child(9):hover
,#cconversion td:nth-child(11):hover,#cconversion td:nth-child(12):hover,#cconversion td:nth-child(13):hover
,#cconversion td:nth-child(15):hover,#cconversion td:nth-child(16):hover,#cconversion td:nth-child(17):hover
{
    cursor: auto !important;
    background: rgb(255,255,255) !important;
    color: black !important;
} */

#cconversion td:nth-child(1):active,
#cconversion td:nth-child(3):active,
#cconversion td:nth-child(4):active,
#cconversion td:nth-child(5):active,
#cconversion td:nth-child(7):active,
#cconversion td:nth-child(8):active,
#cconversion td:nth-child(9):active,
#cconversion td:nth-child(11):active,
#cconversion td:nth-child(12):active,
#cconversion td:nth-child(13):active,
#cconversion td:nth-child(15):active,
#cconversion td:nth-child(16):active,
#cconversion td:nth-child(17):active {
    zoom: 1 !important;
}

.cvtable td:hover {
    cursor: pointer !important;
    background: rgb(0, 181, 173) !important;
    color: white !important;
}

#cconversion td:active {
    zoom: 0.95 !important;
    background: rgb(0, 181, 173) !important;
}

#cconversion td {
    font-size: 90% !important;
}

.cconversion td {
    text-align: center !important;
}

.masterDiv {
    overflow-x: scroll !important;
    overflow-y: scroll !important;
    max-height: 100vh !important;
}

/* width */
.masterDiv::-webkit-scrollbar {
    height: 12px !important;
}


.firstR {
    color: white !important;
}

.cvtable td:first-child {
    background: rgb(73, 82, 133, 1) !important;
}

.cvtable td:first-child:hover {
    background: rgb(73, 82, 133, 0.7) !important;
}

.cvtable td:first-child:hover .firstR {
    color: black !important;
}

.cvtable td:first-child label {
    font-weight: bold !important;
}

.cconversion tbody tr:last-child td {
    font-weight: bold !important;
}

.cconversion thead th:first-child {
    width: 10% !important;
}

.cconversion thead th:nth-child(2) {
    width: 15% !important;
}

.cconversion thead th:nth-child(3) {
    width: 25% !important;
}

.cconversion thead th:nth-child(4) {
    width: 20% !important;
}

.cconversion thead th:nth-child(5) {
    width: 15% !important;
}

.cconversion thead th:nth-child(6) {
    width: 15% !important;
}

.cvtable tr:nth-child(1) td:first-child,
.cvtable tr:nth-child(1),
.cvtable tr:nth-child(1):hover td {
    background: rgb(37, 40, 57) !important;
    color: white !important;
}

.cvtable tr:nth-child(1) td,
.cvtable tr:nth-child(1) label {
    color: white !important;
}

.cvtable tr:nth-child(1) span,
.cvtable tr:nth-child(1):hover span {
    color: coral !important;
}

.cvtable tr:nth-child(1):hover td,
.cvtable tr:nth-child(1):hover label {
    color: white !important;
}

.cvtable tr:nth-child(1) td:first-child label {
    color: rgb(73, 82, 133, 1) !important;
}

.cvtable tr:nth-child(1):hover td:first-child label {
    color: rgb(37, 40, 57) !important;
}

.cvtable tr:nth-child(1) td .laButton,
.cvtable tr:nth-child(1) td:first-child label {
    display: none !important;
}

.cvtable tr:first-child td {
    z-index: 1000 !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    background: rgb(37, 40, 57) !important;
    color: white !important;
    top: 7% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

.description .ui.fixed.table td,
.ui.fixed.table th {
    white-space: pre-wrap !important;
}

.mainDivCV .clndr-calendars-wrapper,
.mainDivCV .clndr-control,
.mainDivCV .clndr-days,
.mainDivCV .clndr-button-today {
    background: #fff !important;
}

.mainDivCV .clndr-calendars-wrapper {
    width: 102% !important;
    margin: -1% 0% -1% -1% !important;
}

.mainDivCV .clndr-control {
    padding: 3% 2% 0% 2% !important;
}

.mainDivCV .clndr-button-today {
    width: 102% !important;
    margin: -1% 0% -1% -1% !important;
}
