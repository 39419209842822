.tdAuthor {
  text-align: right !important;
}

.tdAuthors {
  padding-right: 3% !important;
}

.tdDeclarate {
  padding-left: 1% !important;
}
