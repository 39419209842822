.c{
background-color: #56bfb5;
border-color: #56bfb5;
color: #fff;
/* padding: 5px; */
border-radius: 3px;
font-size: 14px;
font-weight: bold;
}

body{
    overflow: hidden !important;
}

::-webkit-scrollbar{
    width: 10pt !important;
}

::-webkit-scrollbar-thumb{
    border-radius: 0px !important;
    background-color: rgb(0,181,173) !important;
}

::-webkit-scrollbar-thumb:hover{
    background-color: rgba(0,181,173,0.8) !important;
}

::-webkit-input-placeholder {
    color: rgba(0,0,0,0.7) !important;
}

.dIGrPx .iFUdnr{
    width: 99% !important;
}

.sc-bdnylx .jMhaxE .sticky-table-table{
    width: 100% !important;
}

.sticky-table div{
    border: 1pt solid rgb(73, 82, 133) !important;
}

.sticky-table div {
    vertical-align: middle !important;
}

.dIGrPx .sc-AxiKw:not(:nth-last-child(-n+1)) .sc-AxirZ{
    border-bottom: 0px solid black !important;
    border-right: 0px solid #e5e5e5;
}

.conversion thead th { 
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

.description{
    padding: 1% !important;
}