.ui.fluid.dropdown {
  width: 93%;
}

.inputSearchDesktop {
  width: 300px;
}

.SearchBoxItemDesktop {
  width: 17.6vw !important;
  height: auto !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  max-height: 650% !important;
  margin-top: 7.8vh !important;
}
