#tblSM tr:last-child {
    font-weight: bold !important;
    background: gainsboro !important;
}

#cell_table tr:last-child td {
    font-weight: bolder !important;
    background: gainsboro !important;
}

#cell_table1 tr:last-child td {
    font-weight: bolder !important;
    background: gainsboro !important;
}


.thwidth {
    width: 14.2% !important;
}


.outer {
    width: 100%;
    text-align: center;
}

.inner {
    position: absolute;
    left: 50%;
}

/* .conversion thead th { 
    position: sticky !important;
    background: rgb(20, 23, 37) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

#conversion {
    width: 99% !important;
    border: 3pt solid rgb(20, 23, 37) !important;
}

#conversion tr{
    border-bottom: 3pt solid rgb(20, 23, 37) !important;
}

#conversion td {
    vertical-align: middle !important;
}

#conversion td:first-child:hover, #conversion td:last-child:hover{
    background: none !important;
    color:black !important;
    cursor: auto !important;
}

#conversion td:hover{
    cursor: pointer !important;
    background: rgb(0,181,173) !important;
    color: white !important;
}

#conversion td:active{
    zoom: 0.95 !important;
    background: rgb(20, 23, 37) !important;
} */

.cvvtable td:hover {
    cursor: pointer !important;
    background: rgb(0, 181, 173) !important;
    color: white !important;
}

#cell_table td {
    text-align: center !important;
}

.ui.modal>.close {
    font-size: 2.25em !important;
}


#cell_table thead th {
    z-index: 999 !important;
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

#cell_table1 td {
    text-align: center !important;
}


#cell_table1 thead th {
    z-index: 999 !important;
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}


.nrprewrap td {
    white-space: nowrap !important;
}

.nrprewrap b {
    white-space: nowrap !important;
}

.masterDiv .segment {
    height: 9vh !important;
    white-space: pre-wrap !important;
}

.masterDiv .segment span {
    word-wrap: break-word !important;
    white-space: pre-wrap !important;
}

.ui.fixed.table td,
.ui.fixed.table th {
    white-space: inherit !important;
}

.filtering {
    margin-left: 0% !important;
}

.filtering span {
    font-size: 85% !important;
}

.divvipnj {
    position: absolute !important;
    width: 98.5vw !important;
    height: 100vh !important;
    background: rgba(0, 0, 0, 0.5) !important;
    z-index: 1000 !important;
    margin-left: 1.5vw !important;
}

.divvipnj1 {
    margin: 10% 20% !important;
    background: rgba(255, 255, 255, 1) !important;
    height: 61vh !important;
    border-radius: 0pt 0pt !important;
}

.divvipnj1 .field {
    margin-top: 1% !important;
}

.divvipnj1 .field input {
    width: 14.7vw !important;
}

.btnClose {
    font-size: 90% !important;
    position: absolute !important;
    background: darkred !important;
    border-radius: 1pt !important;
    margin: -2.3% -1% -2% 0.7% !important;
}

.btnClose:active {
    opacity: 0.7 !important;
}

.btnView {
    margin-left: 1% !important;
}

.divvipnj1 .clndr-days {
    background-color: white !important;
}

.divvipnj1 .ui.fluid.button {
    margin-top: 0px !important;
}

.divvipnj1 #conversion {
    width: 100% !important;
}

.vipnj span {
    color: white !important;
}

.vipnj button {
    background-color: grey !important;
}

.vipnj button:active {
    background-color: teal !important;
}

.njvip {
    text-align: center !important;
}


.njvip td {
    text-align: center !important;
}

.description .ui.fixed.table td,
.ui.fixed.table th {
    white-space: normal !important;
}


#conversion thead th {
    z-index: 1 !important;
}