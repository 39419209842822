.divbgIMG {
  /* background-size: 50% !important; */
  /* background-position: center !important;
  background-repeat: no-repeat !important;
  background-image: url("./LetterHead.jpeg"); */
  position: absolute;
  width: 1000px;
  margin-left: 7.2%;
  height: 100vh !important;
  z-index: -1 !important;
}

img:hover {
  transform: none !important;
}
