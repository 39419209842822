
.radioForm label{
    color: white !important;
}

.sticky-table-table{
    widows: 100% !important;
}

.popup-content{
    border: 0.3rem solid;
    border-image: linear-gradient(45deg, rgb(0,143,104), rgb(250,224,66)) 1 !important;
    padding: 1rem !important;
    background-color: teal !important;
    width: 19rem !important;
    z-index:1000 !important;
}

.popup-content div,.popup-content div b{
    color: white !important;
}

.trigger{
    border:none !important;
    padding: 0.58rem;
    border-radius: 0.3rem !important;
    margin-left: 2.35rem !important;
}

.trigger {
    border-color: rgb(73, 82, 133) !important;
    font-weight: bold !important;
    color: white;
    background-color: #00b5ad !important;
    opacity: 1;
    animation: fade 2s linear;
}

@keyframes fade {
  0% { opacity: 0 }
  50% { opacity: 1 }
}

.checkbox label{
    color: white !important;
}

i{
    color: black !important;
}

.iFUdnr{
    width:100% !important;
}

table{
    border-spacing: 0px !important;
    border-collapse: collapse !important;
}

.scrolling.dimmable.dimmed>.dimmer{
    overflow: hidden !important;
}

.ui.modal .scrolling.content{
    max-height: calc(80vh) !important;
}

.modals.dimmer .ui.scrolling.modal{
    max-height: calc(95vh) !important;
    margin-top: 1% !important;
}

::-webkit-input-placeholder {
    color: rgba(0,0,0,0.7) !important;
} 

#conversion thead th {
    font-size: 85% !important; 
    z-index: 999 !important;
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

.conversion thead th { 
    position: sticky !important;
    background: rgb(73, 82, 133) !important;
    color: white !important;
    top: -0.5% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

#conversion {
    width: 99% !important;
    border: 3pt solid rgb(73, 82, 133) !important;
}

#conversion tr{
    border-bottom: 3pt solid rgb(73, 82, 133) !important;
}

#conversion td {
    vertical-align: middle !important;
}

#conversion td:first-child:hover, .cvtable td:nth-child(2):hover, .cvtable td:nth-child(3):hover, .cvtable td:nth-child(4):hover{
    background: none !important;
    color:black !important;
    cursor: auto !important;
}

.cvtable td:hover{
    cursor: pointer !important;
    background: rgb(0,181,173) !important;
    color: white !important;
}

#conversion td:active{
    zoom: 0.95 !important;
    background: rgb(73, 82, 133) !important;
}

#conversion td:nth-child(2):active{
    zoom: 1 !important;
    background: rgb(255, 255, 255) !important;
}

.conversion td{
    text-align: center !important;
}

.masterDiv{
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    max-height: 100vh !important;
}

 /* width */
 .masterDiv::-webkit-scrollbar {
    height: 12px !important;
  }


  .firstR{
    color: white !important;
  }

  .cvtable td:first-child{
    background: rgb(73, 82, 133, 1) !important;
 }

 .cvtable td:first-child:hover{
    background: rgb(73, 82, 133, 0.7) !important;
 }

 .cvtable td:first-child:hover .firstR{
    color: black !important;
 }

 .cvtable td:first-child label{
    font-weight: bold !important;
 }

 
.conversion thead th:first-child {
    width: 10% !important;
}

.conversion thead th:nth-child(2) {
    width: 22% !important;
}

.conversion thead th:nth-child(3) {
    width: 28% !important;
}

.conversion thead th:nth-child(5), .conversion thead th:nth-child(4) {
    width: 12% !important;
}

.cvtable tr:nth-child(1) td:first-child,.cvtable tr:nth-child(1),.cvtable tr:nth-child(1):hover td{
    background:rgb(37, 40, 57) !important;
    color:white !important;
}

.cvtable tr:nth-child(1) td,.cvtable tr:nth-child(1) label{
    color:white !important;
}

.cvtable tr:nth-child(1) span, .cvtable tr:nth-child(1):hover span{
    color:coral !important;
}

.cvtable tr:nth-child(1):hover td,.cvtable tr:nth-child(1):hover label{
    color:white !important;
}

.cvtable tr:nth-child(1) td:first-child label{
    color: rgb(73, 82, 133, 1) !important;
}

.cvtable tr:nth-child(1):hover td:first-child label{
    color: rgb(37, 40, 57) !important;
}

.cvtable tr:nth-child(1) td .laButton, .cvtable tr:nth-child(1) td:first-child label{
    display:none !important;
}

#conversion tr:first-child td:active{
    zoom: 1 !important;
    background: rgb(37, 40, 57) !important;
}

.cvtable tr:first-child td { 
    z-index: 1000 !important;
    position: sticky !important;
    background: rgb(37, 40, 57) !important;
    color: white !important;
    top: 7% !important;
    padding: 1.5% 0% 1.5% 0% !important;
    width: auto !important;
}

.description .ui.fixed.table td, .ui.fixed.table th{
    white-space:pre-wrap !important;
}

.mainDivCV .clndr-calendars-wrapper, 
.mainDivCV .clndr-control, 
.mainDivCV .clndr-days, 
.mainDivCV .clndr-button-today{
    background: #fff !important;
}

.mainDivCV .clndr-calendars-wrapper{
    width: 102% !important;
    margin: -1% 0% -1% -1% !important;
}

.mainDivCV .clndr-control{
    padding: 3% 2% 0% 2% !important;
}

.mainDivCV .clndr-button-today{
    width: 102% !important;
    margin: -1% 0% -1% -1% !important;
}