body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ui.secondary.vertical.pointing.menu .active.item{
  background: #00b5ad;
  border:none !important;
}

i.circular.icon{
  z-index: 1000 !important;
  font-size:1.3rem !important;
  margin: 1rem 0rem 0rem 0rem !important;
}