.link{
  font-weight: 800;
  color: #eeeeee;
  font-size:14;
}
.link_span{
  margin: 5px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
table{
border-spacing: 10px;
    border-collapse: separate}

    .btn-primary {
      color: #fff;
      background-color: #337ab7;
      border-color: #2e6da4;
      padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    }
    .btn-primary:focus,
    .btn-primary.focus {
      color: #fff;
      background-color: #286090;
      border-color: #122b40;
    }
    .btn-primary:hover {
      color: #fff;
      background-color: #286090;
      border-color: #204d74;
    }
    .btn-primary:active,
    .btn-primary.active,
    .open > .dropdown-toggle.btn-primary {
      color: #fff;
      background-color: #286090;
      border-color: #204d74;
    }

    .btn-success {
      color: #fff;
      background-color: #5cb85c;
      border-color: #4cae4c;
      padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    }
    .btn-success:focus,
    .btn-success.focus {
      color: #fff;
      background-color: #449d44;
      border-color: #255625;
    }
    .btn-success:hover {
      color: #fff;
      background-color: #449d44;
      border-color: #398439;
    }
    .btn-success:active,
    .btn-success.active,
    .open > .dropdown-toggle.btn-success {
      color: #fff;
      background-color: #449d44;
      border-color: #398439;
    }

    .btn-danger {
      color: #fff;
      background-color: #d9534f;
      border-color: #d43f3a;
      padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    }
    .btn-danger:focus,
    .btn-danger.focus {
      color: #fff;
      background-color: #c9302c;
      border-color: #761c19;
    }
    .btn-danger:hover {
      color: #fff;
      background-color: #c9302c;
      border-color: #ac2925;
    }
    .btn-danger:active,
    .btn-danger.active,
    .open > .dropdown-toggle.btn-danger {
      color: #fff;
      background-color: #c9302c;
      border-color: #ac2925;
    }

    .btn-warning {
      color: #fff;
      background-color: #f0ad4e;
      border-color: #eea236;
      padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    }
    .btn-warning:focus,
    .btn-warning.focus {
      color: #fff;
      background-color: #ec971f;
      border-color: #985f0d;
    }
    .btn-warning:hover {
      color: #fff;
      background-color: #ec971f;
      border-color: #d58512;
    }
    .btn-warning:active,
    .btn-warning.active,
    .open > .dropdown-toggle.btn-warning {
      color: #fff;
      background-color: #ec971f;
      border-color: #d58512;
    }