.ui.fluid.dropdown {
  width: 93%;
}
.inputSearchDesktop {
  width: 300px;
}
.SearchBoxItemDesktop {
  width: 69.5% !important;
  height: auto !important;
  overflow-y: scroll !important;
  max-height: 720% !important;
}
